import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';
// import axios from 'axios';
import { Loading } from '../Loading/Loading';
import "./style.css";


export const UnverifiedEmail = () => {
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    if (!isAuthenticated) {
        return <div>please login first</div>
    }

    const sendToServer = async () => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }

        setLoading(true);
        setMessage('');

        try {
            const token = await getAccessTokenSilently();
            const response = await fetch('/resumasterapi/resend-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ userId: user.sub })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setMessage(data.message);
        } catch (error) {
            console.error('Failed to send verification email:', error);
            setMessage('Failed to send verification email. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="verification-screen">
        
            <div className="verification-window">
            <img className="email-icon" src="/img/email-icon-purple.png" />
                <h1 className="verify_email_title">Verify your email address</h1>
                <p className="verify_email_text">Please verify your email address by clicking the button below.</p>
                {/* <button onClick={handleButtonClick} className={emailSent ? 'button-sent' : ''}>
                    {emailSent ? 'Email Sent' : 'Verify My Email'}
                </button> */}
            <button className="send-btn" onClick={sendToServer} disabled={loading}>
                {loading ? 'Sending...' : 'Verify Email Address'}
            </button>

            <p className="verify_email_text">If you haven't received our verification email, please check the spam or junk folder in your email. If it's not there, contact us at <a href="mailto:admin@resumaster.ai">admin@resumaster.ai</a>, and we'll assist you promptly.</p>
            
            {message && <p className="verify_error_message">{message}</p>}
            
            </div>
        </div>
    );
};