import React, { useState, useRef, useEffect } from 'react';
import './Applications.css';
import JobDescriptionModal from '../ApplicationHistory/JobDescriptionModal';
import CoverletterModal from '../ApplicationHistory/CoverletterModal';
import ResumeModal from '../ApplicationHistory/ResumeModal'
import { useAuth0 } from "@auth0/auth0-react";

const Applications = ({ company, expandedId, setExpandedId, onSubmit, onDelete }) => {

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const dropdownRef = useRef(null);
    const isExpanded = company.id === expandedId;
    const [originalCompany, setOriginalCompany] = useState(company);
    // const [tempCompany, setTempCompany] = useState(company);
    const convertToLocalTime = (dateString) => {
        if (!dateString) return null;
        const utcDate = new Date(dateString + 'Z');
        return utcDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2');
    };
    const [tempCompany, setTempCompany] = useState(() => ({
        ...company,
        applied_date: convertToLocalTime(company.applied_date),
        interview_date: convertToLocalTime(company.interview_date),
        screen_date: convertToLocalTime(company.screen_date),
        offer_date: convertToLocalTime(company.offer_date),
        rejected_date: convertToLocalTime(company.rejected_date)
    }));

    // console.log(originalCompany)
    // console.log(tempCompany)

    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const statusOptions = ['Applied', 'Screen', 'Interview', 'Offer', 'Rejected'];
    const [resumeApiResponse, setResumeApiResponse] = useState(null);
    const [isResumeOpen, setIsResumeOpen] = useState(false);

    const formatDefaultTime = (date) => {

        const tempYear = date.getFullYear();
        const tempMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 返回 0-11，所以我们加 1
        const tempDay = date.getDate().toString().padStart(2, '0');
        const tempHours = date.getHours().toString().padStart(2, '0');
        const tempMinutes = date.getMinutes().toString().padStart(2, '0');
        const tempSeconds = date.getSeconds().toString().padStart(2, '0');
    
        // 返回格式化的年月日时分秒
        return `${tempYear}-${tempMonth}-${tempDay} ${tempHours}:${tempMinutes}:${tempSeconds}`;
    };

    // const [selectedDate, setSelectedDate] = useState(formatDefaultTime(new Date()));
    const [selectedDate, setSelectedDate] = useState(tempCompany[`${tempCompany.status.toLowerCase()}_date`]);
    
    const [isjobDescriptionModalOpen, setIsjobDescriptionModalOpen] = useState(false);
    // console.log(isjobDescriptionModalOpen);

    const openJobDescriptionModal = () => setIsjobDescriptionModalOpen(true);
    const closeJobDescriptionModal = () => setIsjobDescriptionModalOpen(false);

    const [isCoverletterModalOpen, setIsCoverletterModalOpen] = useState(false);
    // console.log(isCoverletterModalOpen);

    const openCoverletterModal = () => setIsCoverletterModalOpen(true);
    const closeCoverletterModal = () => setIsCoverletterModalOpen(false);

    const openResumeModal = () => setIsResumeOpen(true);
    const closeResumeModal = () => {
        setIsResumeOpen(false); // Close modal
        setResumeApiResponse(null); // Reset data when modal closes
    };


    const formatDateTimeToUTC = (dateString) => {
        // 解析输入的日期字符串
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
    
        // 创建一个新的 Date 对象，使用输入的年月日时分秒
        const localDate = new Date(year, month - 1, day, hours, minutes, seconds);
    
        // 格式化 UTC 时间为字符串
        const utcYear = localDate.getUTCFullYear();
        const utcMonth = String(localDate.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(localDate.getUTCDate()).padStart(2, '0');
        const utcHours = String(localDate.getUTCHours()).padStart(2, '0');
        const utcMinutes = String(localDate.getUTCMinutes()).padStart(2, '0');
        const utcSeconds = String(localDate.getUTCSeconds()).padStart(2, '0');
    
        return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
    };

    const formatDateTimeToLocal = (dateString) => {
        // 解析输入的日期字符串
        const [year, month, day] = dateString.split('-').map(Number);
    
        // 获取当前的本地时间
        const now = new Date();
    
        // 创建一个新的 Date 对象，使用输入的年月日和当前的时分秒
        const localDate = new Date(year, month - 1, day, now.getHours(), now.getMinutes(), now.getSeconds());
    
        // 获取年月日时分秒
        const tempYear = localDate.getFullYear();
        const tempMonth = (localDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 返回 0-11，所以我们加 1
        const tempDay = localDate.getDate().toString().padStart(2, '0');
        const tempHours = localDate.getHours().toString().padStart(2, '0');
        const tempMinutes = localDate.getMinutes().toString().padStart(2, '0');
        const tempSeconds = localDate.getSeconds().toString().padStart(2, '0');
    
        // 返回格式化的年月日时分秒
        return `${tempYear}-${tempMonth}-${tempDay} ${tempHours}:${tempMinutes}:${tempSeconds}`;
    };
    

    const handleButtonClick = (e, modalOpener) => {
        e.stopPropagation(); // 阻止事件冒泡
        if (!isExpanded) {
            setExpandedId(company.id);
        }
        modalOpener();
    };

    const handleClickViewResume = async (e, applicationID) => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        e.stopPropagation();
        if (!isExpanded) {
            setExpandedId(company.id);
        }

        try {
            const token = await getAccessTokenSilently();
            const formData = new FormData();
            formData.append('application_id', applicationID);

            const response = await fetch('/resumasterapi/fetch_application_resume_json', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 400 || response.status === 500) {
                    throw new Error('Sorry, there is some issue with our server. Please try again later.');
                }
                if (response.status === 401) {
                    throw new Error('Sorry, you must login first.');
                }
            }

            const data = await response.json();
            if (data) {
                setResumeApiResponse(JSON.parse(data));
                setIsResumeOpen(true); // Show modal after fetching data successfully
            } else {
                console.log('Resume data is null');
            }
        } catch (error) {
            console.error(error);
            alert(error.message);
        }
    };

    useEffect(() => {
        setOriginalCompany(company);
        setTempCompany({
            ...company,
            applied_date: convertToLocalTime(company.applied_date),
            interview_date: convertToLocalTime(company.interview_date),
            screen_date: convertToLocalTime(company.screen_date),
            offer_date: convertToLocalTime(company.offer_date),
            rejected_date: convertToLocalTime(company.rejected_date)
        });
        // setSelectedDate(formatDefaultTime(new Date()));
        setSelectedDate(tempCompany[`${tempCompany.status.toLowerCase()}_date`]);
    }, [company]);

    const statusColors = {
        Applied: '#6750A4',
        Screen: '#D29C27',
        Interview: '#497DA1',
        Offer: '#53765C',
        Rejected: '#AAAAAA'
    };

    const statusBackgroundColors = {
        Applied: '#E9E3FB',
        Screen: '#F9F3E6',
        Interview: '#E3EEFB',
        Offer: '#DEECDC',
        Rejected: '#E5E5EA'
    }

    const statusItemStyle = (status) => ({
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: `${statusBackgroundColors[status]}`,
        borderRadius: '16px',
        padding: '4px 8px',
        marginLeft: '4px'
    });
    
    const dotStyle = (status) => ({
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: statusColors[status],
        marginRight: '6px'
    });

    const toggleExpand = (e) => {
        e.stopPropagation();
        if (isExpanded) {
            setTempCompany({
                ...originalCompany,
                applied_date: convertToLocalTime(originalCompany.applied_date),
                interview_date: convertToLocalTime(originalCompany.interview_date),
                screen_date: convertToLocalTime(originalCompany.screen_date),
                offer_date: convertToLocalTime(originalCompany.offer_date),
                rejected_date: convertToLocalTime(originalCompany.rejected_date)
            });
        }
        setExpandedId(isExpanded ? null : company.id);
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return dateString.split(' ')[0];
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTempCompany(prev => ({ ...prev, [name]: value }));
    };

    const handleStatusChange = (status, e) => {
        e.stopPropagation(); // 阻止事件冒泡
        setIsStatusOpen(false);
        
        setTempCompany(prev => {
            const updatedCompany = { ...prev, status: status };
            const currentIndex = statusOptions.indexOf(status);
            
            statusOptions.forEach((option, index) => {
                const dateKey = `${option.toLowerCase()}_date`;
                if (index === currentIndex) {
                    // console.log(11111111111111111111)
                    updatedCompany[dateKey] = selectedDate;
                } else if (index > currentIndex || 
                    (status === 'Offer' && option === 'Rejected') || 
                    (status === 'Rejected' && option === 'Offer')) {
                    updatedCompany[dateKey] = null;
                }
            });
    
            return updatedCompany;
        });
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        // console.log(newDate)
        // const utcDateString = formatDateTimeToUTC(newDate);
        // console.log(utcDateString)
        const localDate = formatDateTimeToLocal(newDate)
        setSelectedDate(localDate);
        setTempCompany(prev => {
            const dateKey = `${prev.status.toLowerCase()}_date`;
            return { ...prev, [dateKey]: localDate };
        });
    };

    const getDisplayDate = () => {
        const statusDate = tempCompany[`${tempCompany.status.toLowerCase()}_date`];
        if (statusDate) {
            return formatDate(statusDate);
            // return statusDate;
        }
        // 如果当前状态没有日期，尝试找到最近的有日期的状态
        const currentIndex = statusOptions.indexOf(tempCompany.status);
        for (let i = currentIndex; i >= 0; i--) {
            const date = tempCompany[`${statusOptions[i].toLowerCase()}_date`];
            if (date) {
                return formatDate(date);
                // return date;
            }
        }
        return '';
    };

    const statusToSliderValue = {
        'Applied': 0,
        'Screen': 1,
        'Interview': 2,
        'Offer': 3,
        'Rejected': 3
    };

    const getSliderLabels = () => {
        const currentIndex = statusOptions.indexOf(tempCompany.status);
        return statusOptions.map((status, index) => {
            if ((status === 'Offer' || status === 'Rejected') && status !== tempCompany.status) {
                return null;
            }
            if (index > currentIndex) return null;
        
            return (
                <div key={status} style={{
                    position: 'absolute',
                    left: `${(statusToSliderValue[status] / 3) * 100}%`,
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    fontWeight: 600,
                    color: '#2a2a2a',
                    // fontSize: '0.95em',
                    whiteSpace: 'nowrap',
                }}>
                    <span style={{ marginBottom: '5px' }}>
                        {status}
                    </span>
                </div>
            );
        }).filter(Boolean);
    };
    
    const getSliderDates = () => {
        return statusOptions.map((status, index) => {
            const dateKey = `${status.toLowerCase()}_date`;
            const date = tempCompany[dateKey];
    
            return (
                <div key={status} style={{
                    position: 'absolute',
                    left: `${(statusToSliderValue[status] / 3) * 100}%`,
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                }}>
                    {date && (
                        <span style={{ fontSize: '0.9em', color: '#666' }}>
                            {formatDate(date)}
                        </span>
                    )}
                </div>
            );
        });
    };

    const renderStageMarkers = () => {
        return statusOptions.map((status, index) => {
            const isSelected = statusOptions.indexOf(tempCompany.status) >= index;
            const style = {
                left: status === 'Rejected' ? '100%' : `${(index / 3) * 100}%`
            };
            return (
                <div
                    key={status}
                    className={`stage-marker ${isSelected ? 'selected' : 'unselected'} ${status === 'Rejected' ? 'rejected' : ''}`}
                    style={style}
                />
            );
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("Temp company: ", tempCompany)
        const updatedCompany = {
            ...tempCompany,
            applied_date: tempCompany.applied_date ? formatDateTimeToUTC(tempCompany.applied_date) : null,
            screen_date: tempCompany.screen_date ? formatDateTimeToUTC(tempCompany.screen_date) : null,
            interview_date: tempCompany.interview_date ? formatDateTimeToUTC(tempCompany.interview_date) : null,
            offer_date: tempCompany.offer_date ? formatDateTimeToUTC(tempCompany.offer_date) : null,
            rejected_date: tempCompany.rejected_date ? formatDateTimeToUTC(tempCompany.rejected_date) : null
        };
        // setOriginalCompany(updatedCompany);
        // 这里应该调用一个函数来更新父组件中的数据
        // console.log("Saving changes:", updatedCompany);
        onSubmit(updatedCompany);
        setExpandedId(null);
        // console.log(originalCompany)
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        // 这里应该调用一个函数来删除这个应用
        console.log("Deleting application:", tempCompany.id);
        onDelete(tempCompany.id);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsStatusOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`applications-card ${isExpanded ? 'expanded' : ''}`}>
            <div className="applications-header" onClick={toggleExpand}>
                <div className="company-info">
                    <div className="company-icon">
                        <img className="logo" alt="company_logo" src={tempCompany.domain === null ? "/img/company_default.png" : tempCompany.domain} />
                    </div>
                    <div className="company-details">
                    <h2 className="applications-company-name">
                        {tempCompany.company_name ? tempCompany.company_name : 'Company Not Available'}
                    </h2>
                    <p className="applications-position">
                        {tempCompany.position ? tempCompany.position : 'Job Position Not Available'}
                    </p>
                        <div className="applications-actions">

                            {tempCompany.job_description && (
                                <>
                                    <button className="view-btn" onClick={(e) => handleButtonClick(e, openJobDescriptionModal)}>
                                        View Job Description
                                    </button>
                                    <JobDescriptionModal isOpen={isjobDescriptionModalOpen} onClose={closeJobDescriptionModal} applicationID={company.id}/>
                                </>
                            )}
                            {tempCompany.resume_json && (
                                <>
                                    <button className="view-btn" onClick={(e) => handleClickViewResume(e, company.id)}>
                                        View Resume
                                    </button>
                                    {isResumeOpen && resumeApiResponse && (
                                        <ResumeModal
                                            isOpen={isResumeOpen}
                                            onClose={closeResumeModal}
                                            resumeData={resumeApiResponse}
                                        />
                                    )}
                                </>
                            )}
                            {tempCompany.coverletter_json && (
                                <>
                                    <button className="view-btn" onClick={(e) => handleButtonClick(e, openCoverletterModal)}>
                                        View Cover Letter
                                    </button>
                                    <CoverletterModal isOpen={isCoverletterModalOpen} onClose={closeCoverletterModal} applicationID={company.id}/>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="applications-meta">
                    <div style={statusItemStyle(originalCompany.status)}>
                        <div style={dotStyle(originalCompany.status)}></div>
                        <span>{originalCompany.status}</span>
                    </div>
                    <span className="applications-date">Applied on: {formatDate(tempCompany.applied_date)}</span>
                </div>
            </div>
            {isExpanded && (
                <div className="expanded-section" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={handleSubmit} className="add-application-form">
                        {/* <div className="add-application-form-row">
                            <div className="add-application-form-group">
                                <label className="add-application-label">Company</label>
                                <input 
                                    type="text" 
                                    name="company_name"
                                    value={tempCompany.company_name} 
                                    onChange={handleInputChange}
                                    className="add-application-input"
                                />
                            </div>
                            <div className="add-application-form-group">
                                <label className="add-application-label">Position Title</label>
                                <input 
                                    type="text" 
                                    name="position"
                                    value={tempCompany.position} 
                                    onChange={handleInputChange}
                                    className="add-application-input"
                                />
                            </div>
                        </div> */}
                        <div className="application-card-divider"></div>
                        <div className="add-application-form-status-row">
                            <div className="add-application-form-status">
                                <label className="add-application-label">Application Status</label>
                                <div ref={dropdownRef} className="application-status-dropdown">
                                    <div className="add-application-input-temp" onClick={(e) => { e.stopPropagation(); setIsStatusOpen(!isStatusOpen); }} tabIndex="0">
                                        <div style={statusItemStyle(tempCompany.status)}>
                                            <div style={dotStyle(tempCompany.status)}></div>
                                            <span style={{textTransform: 'capitalize'}}>{tempCompany.status}</span>
                                        </div>
                                    </div>
                                    {isStatusOpen && (
                                        <div className="application-status-dropdown-open">
                                            {statusOptions.map((status) => (
                                                <div className="applications-status-dropdown-item" key={status} onClick={(e) => handleStatusChange(status, e)}>
                                                    <div style={statusItemStyle(status)}>
                                                        <div style={dotStyle(status)}></div>
                                                        <span style={{textTransform: 'capitalize'}}>{status}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="add-application-form-timeline">
                                    <div className="status-timeline-title">
                                        {getSliderLabels()}
                                        </div>
                                    <div className="status-slider-container">
                                        {renderStageMarkers()}
                                        <input
                                            type="range"
                                            min="0"
                                            max="3"
                                            value={statusToSliderValue[tempCompany.status]}
                                            className="status-slider"
                                            style={{ '--slider-value': statusToSliderValue[tempCompany.status] }}
                                            readOnly
                                        />
                                    </div>
                                    <div className="status-timeline-date" >
                                        {getSliderDates()}
                                    </div>
                            </div>
                        </div>
                        <div className="add-application-form-date">
                            <label className="add-application-label">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={getDisplayDate()}
                            onChange={handleDateChange}
                            required
                            className="add-application-input-temp"
                            onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                            onClick={(e) => e.target.showPicker()} // Show the calendar picker on click
                        />
                        </div>
                        
                        <div className="add-application-form-btns">
                            <div className="add-application-button-group">
                                <button type="button" onClick={handleDelete} className="global-cancel-button">Delete</button>
                                <button type="submit" className="global-save-button">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};
 
export default Applications;