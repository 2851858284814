import React, { useState, useRef, useEffect } from 'react';

const CompanyInputBar = ({ 
    initialCompanyName = '', 
    initialCompanyDomain = '', 
    onCompanyChange, 
    onDomainChange 
}) => {
    const [targetCompany, setTargetCompany] = useState(initialCompanyName);
    const [resumeCompanyDomain, setResumeCompanyDomain] = useState(initialCompanyDomain);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const typingTimeoutRef = useRef(null); // To store the timeout ID
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    // Effect for handling clicks outside of the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setShowDropdown(false); // Close the dropdown if clicked outside
            }
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    // Handle changes to the input field
    const handleResumeCompanyInputChange = (e) => {
        const newValue = e.target.value;
        setTargetCompany(newValue);

        // Pass company name to parent
        onCompanyChange(newValue);

        if (newValue === '') {
            setResumeCompanyDomain('');
            onDomainChange(''); // Reset the domain in the parent as well
        }

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            if (newValue.trim()) {
                fetchCompanyDomains(newValue); // Call the API after the user stops typing
            }
        }, 600);
    };

    // Fetch domain suggestions based on company input
    const fetchCompanyDomains = (query) => {
        fetch(`/resumasterapi/search_relative_domains?q=${query}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`API call failed with status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setCompanySuggestions(data);
                setShowDropdown(true);
            })
            .catch(error => {
                console.error('Error fetching company domains:', error);
                setCompanySuggestions([]);
                setShowDropdown(false);
            });
    };

    // If the domain is already provided, skip the dropdown logic
    useEffect(() => {
        if (initialCompanyDomain) {
            setResumeCompanyDomain(initialCompanyDomain);
            setShowDropdown(false);
        }
    }, [initialCompanyDomain]);

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            {/* Conditionally render the logo if resumeCompanyDomain is set */}
            {resumeCompanyDomain && (
                <img
                    src={resumeCompanyDomain}
                    alt={targetCompany}
                    style={{
                        position: 'absolute',
                        left: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: '25px',
                        width: '25px',
                        zIndex: 1,
                    }}
                />
            )}
            <input
                ref={inputRef}
                value={targetCompany}
                placeholder="e.g. Google / Amazon / SaaS B2B Company"
                onChange={handleResumeCompanyInputChange}
                className= {`company-container ${targetCompany.trim() === '' ? 'input-error' : ''}`}
                style={{
                    paddingLeft: resumeCompanyDomain ? '40px' : '10px',
                    width: '100%',
                    boxSizing: 'border-box',
                    zIndex: 0,
                }}
            />
            {showDropdown && companySuggestions.length > 0 && (
                <div className="styled-dropdown-container" ref={dropdownRef}>
                    {companySuggestions.map((company, index) => (
                        <div
                            key={index}
                            className="styled-dropdown-item"
                            onClick={() => {
                                setTargetCompany(company.name);
                                setResumeCompanyDomain(company.logo_url);
                                onCompanyChange(company.name);
                                onDomainChange(company.logo_url);
                                setShowDropdown(false); // Close the dropdown after selection
                            }}
                        >
                            <div className="dropdown-logo">
                                <img src={company.logo_url} alt={company.name} />
                            </div>
                            <div className="dropdown-content">
                                <span className="company-name">{company.name}</span>
                                <span className="company-domain">{company.domain}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompanyInputBar;
