import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardExpDetails = ({ data, original_data, selectedExperience, handleExperienceChange, 
    handleExperienceDelete, isFieldEmpty, checkExperienceFields, setIsExperienceFieldsFilled}) => {
    const textAreaRef = useRef(null);

    const [experienceDetails, setExperienceDetails] = useState({
        Title: '',
        Duration: '',
        Location: '',
        Company: '',
        Responsibilities: [],
        type: ''
    });

    const [rawInput, setRawInput] = useState('');

    useEffect(() => {
        if (data && data[selectedExperience]) {
            setExperienceDetails({
                Title: data[selectedExperience].Title,
                Duration: data[selectedExperience].Duration,
                Location: data[selectedExperience].Location,
                Company: data[selectedExperience].Company,
                Responsibilities: data[selectedExperience].Responsibilities,
                type: data[selectedExperience].type
            });
        }
    }, [selectedExperience, data]);
    


    useEffect(() => {
        setIsExperienceFieldsFilled(checkExperienceFields(experienceDetails));
    }, [experienceDetails, checkExperienceFields, setIsExperienceFieldsFilled]);

    // Set initial value for rawInput when experienceDetails.Responsibilities changes
    useEffect(() => {
        setRawInput((experienceDetails.Responsibilities || []).map(responsibility => `• ${responsibility}`).join('\n'));
    }, [experienceDetails.Responsibilities]);

    const handleInputChange = (field, value) => {
        const updatedExperienceDetails = { ...experienceDetails, [field]: value };
        setExperienceDetails(updatedExperienceDetails);
        handleExperienceChange(updatedExperienceDetails, selectedExperience);
    };

    const handleDelete = () => {
        handleExperienceDelete(selectedExperience);
    };

    // Handle input change, updating rawInput only
    const handleTextareaChange = (e) => {
        setRawInput(e.target.value);
    };

    const handleTextareaBlur = () => {
        const responsibilities = rawInput.split('\n').map(line => line.replace(/^•\s*/, '').trim()).filter(line => line !== '');
        handleInputChange('Responsibilities', responsibilities);
    };

    const handleTextareaKeyDown = (e) => {
        const { value, selectionStart, selectionEnd } = e.target;
    
        // Handle Enter key to add a bullet point at the beginning of a new line
        if (e.key === 'Enter') {
            const newValue = value.substring(0, selectionStart) + '\n• ' + value.substring(selectionEnd);
            setRawInput(newValue);
    
            // Move the cursor to the correct position after the bullet point
            setTimeout(() => {
                e.target.setSelectionRange(selectionStart + 3, selectionStart + 3);
            }, 0);
    
            e.preventDefault(); // Prevent the default behavior of the Enter key
        }
    };

    const handleCopyToClipboard = (text) => {
        textAreaRef.current.value = text;
        textAreaRef.current.select();
        document.execCommand('copy');
    };

    const navigate = useNavigate();

    if (!data || !data[selectedExperience]) {
        return (
            <div className="education-container">
                <div className="summary-header">
                    <div className="edu-title">No experience data available, you can add your experiences by clicking the "+ Add" button</div>
                </div>
            </div>
            );
    }

    return (
        <div className="main-section">
            <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }}></textarea>
            <div className="notice">
                <div className="dashboard-experience-header">
                    <h4>
                        {data[selectedExperience].type === 'work'
                            ? `Work Experience ${selectedExperience + 1}`
                            : `Project Experience ${selectedExperience + 1}`}
                    </h4>
                    <button onClick={handleDelete} className="delete-button">
                        Delete
                    </button>
                </div>
            </div>

            <div className="description">
                <label className="form-label">Role<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Title || ''}
                    onChange={(e) => handleInputChange('Title', e.target.value)} 
                />
                {(!experienceDetails.Title ||isFieldEmpty(experienceDetails.Title)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Company/Institution<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Company || ''}
                    onChange={(e) => handleInputChange('Company', e.target.value)} 
                />
                {(!experienceDetails.Company ||isFieldEmpty(experienceDetails.Company)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Duration<span className="starColor">*</span></label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Duration || ''}
                    onChange={(e) => handleInputChange('Duration', e.target.value)} 
                />
                {(!experienceDetails.Duration ||isFieldEmpty(experienceDetails.Duration)) && (
                    <div className="hint-text">Hint: this content is required.</div>
                )}

                <label className="form-label">Location</label>
                <input 
                    type="text" 
                    className="experience-form-input" 
                    value={experienceDetails.Location || ''}
                    onChange={(e) => handleInputChange('Location', e.target.value)} 
                />
                <p>Description (Edit Below)</p>
                <div className="revised-content">
                    <textarea
                        className="experience-textarea"
                        value={rawInput}
                        onChange={handleTextareaChange}
                        onKeyDown={handleTextareaKeyDown}  // Add the onKeyDown event handler
                        onBlur={handleTextareaBlur}  // Process the raw input on blur
                        rows={12}
                    />
                </div>
            </div>
        </div>
    );
};

export default DashboardExpDetails;
