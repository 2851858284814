import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import "./style.css";

const CheckoutForm = ({ onPaymentFailure }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://yourdomain.com/order/complete',
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');
      }
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement/>
      <button className="submit-payment-button" type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
