import React from "react";
import ReactDOMClient from "react-dom/client";
import { Auth0Provider } from '@auth0/auth0-react';
import { loadStripe , StripeProvider} from '@stripe/stripe-js';
import { App } from "./App";

// const stripePromise = loadStripe("pk_test_51Oz6bFFHGXCu93g6RZlHkXfxfkGCYCmZ3jdYF9ezhFa7amcCOfafhCm1w1nmrbMKIYAI4wbm234v8eZuhbbgi6Vs00mtDAQpdh");

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

root.render(
    //---------------------------------auhentication code----------------------------------------
    <Auth0Provider
        domain="dev-j2d8h5t322l62ya1.us.auth0.com"
        clientId="pqJz6xCI0cae1YfUDq3XJx4Oa1DUoZjK"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://resumaster.ai/resumasterapi/revise_resume_new"
        }}
      >
            <App />    
      </Auth0Provider>,
    //---------------------------------auhentication code----------------------------------------
    //original only has <App />
    // <App />
);
