import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <>
        <footer className="footer">
            <div className="footer-left">
                @2024 Resumaster
            </div>
            <div className="footer-right">
                <a href="/contact" className="footer-link">Contact</a>
                <a href="/term_of_service" className="footer-link">Terms of Service</a>
                <a href="/privacy_policy" className="footer-link">Privacy Policy</a>
                <a href="https://discord.gg/YURMHY9JNt" className="footer-icon" target="_blank" rel="noopener noreferrer">
                    <img src="/img/ic_baseline-discord.png" alt="discord-icon" />
                </a>
                <a 
                href="https://www.linkedin.com/company/resumaster/" 
                className="footer-icon" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{marginRight: '0px'}}>
                    <img src=" /img/LinkedIn_icon.png" alt="LinkedIn" />
                </a>
            </div>
        </footer>
        <div className="dashboard-logo-api" style={{ marginTop: '5px', paddingLeft: '8%', paddingRight: '8%'}}>
            <a href="https://logo.dev" alt="Logo API">Logos provided by Logo.dev</a>
        </div>
        </>
    );
};

export default Footer;
