
import React from 'react';
import './Reviews.css';

const Review = ({ stars, comment }) => {
    return (
        <div className="review">
            {/* tips:the style of 'star' needs to be reconsidered */}
            <div className="stars">&#x2b50; &#x2b50; &#x2b50; &#x2b50; &#x2b50;</div> 
            <div className="comment">"{comment}"</div>
        </div>
    );
};

export default Review;