import React from 'react';

export function RingScore({ score }) {
  const radius = 30; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDasharray = circumference.toFixed(2);
  const strokeDashoffset = ((100 - score) / 100 * circumference).toFixed(2);

  return (
    <div className="ring-score-container">
      <svg width="100" height="100" viewBox="0 0 120 120">
        <circle
          cx="60" cy="60" r={radius}
          fill="transparent"
          stroke="#ddd"
          strokeWidth="6"
        />
        <circle
          cx="60" cy="60" r={radius}
          fill="transparent"
          stroke="#6750A4"
          strokeWidth="6"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 60 60)"
        />
        <text
          x="50%" 
          y="52%" 
          fill="#6750A4"
          fontSize="28"
          fontWeight="bold"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {score}
        </text>
      </svg>
    </div>
  );
}
