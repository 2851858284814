/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Link } from 'react-router-dom'
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook

export const Navigation = ({ className }) => {
  //---------------------------------auhentication code----------------------------------------
  const { isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  return (
    <div className={`navigation ${className}`}>
      <Link to="/">
      <img
        className="futuristic-modern"
        alt="Futuristic modern"
        src="/img/nav-logo-new.png"
      />
      </Link>

      <div className="navigation-buttons">
        

        <a className="contact-us" href="/contact">
            <div className="button-secondary-text">Contact Us</div>
        </a>
        <a className="button-discord" href="https://reddit.com/r/ResuMaster">
          <img className="discord-icon" src="/img/reddit_icon_purple.png" />
        </a>
        <button className="button-primary" onClick={loginWithRedirect}>
          <div className="button-primary-text">Login</div>
        </button>

      </div>

    </div>
  );
};
