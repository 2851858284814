import React from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./style.css";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

// Loading.js
export function Loading_partial() {
    return (
        <div className="spinner-container">
          <div className="loading-spinner">
          </div>
        </div>
    );
  }