import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';
import { Loading } from '../Loading/Loading';
import "./style.css";

export const UnsubscribeEmail = () => {
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    // Extract the email from the query string
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');  // Gets the 'email' parameter from URL
        if (email) {
            setEmailAddress(email);  // Set the email in the state
        }
    }, []);

    // Simulate sending the email to the server
    const sendToServer = async () => {
        setLoading(true);
        try {
            // Send a POST request to the Flask API with the email in the body
            const response = await fetch('/resumasterapi/unsubscribeEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: emailAddress }) // Send email in request body
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Parse the response data
            const data = await response.json();
            setMessage(data.message);
        } catch (error) {
            setMessage('Failed to unsubscribe email. Please try again or directly email us: admin@resumaster.ai');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="verification-screen">
            <div className="verification-window">
                <img className="email-icon" src="/img/email-icon-purple.png" alt="Email Icon" />
                <h1 className="verify_email_title">Unsubscribe email: {emailAddress}</h1>
                <p className="verify_email_text">Please confirm the email unsubscription by clicking the button below.</p>
                <button className="send-btn" onClick={sendToServer} disabled={loading}>
                    {loading ? 'Sending...' : 'Confirm Unsubscribe'}
                </button>
                {message && <p className="verify_error_message">{message}</p>}
            </div>
        </div>
    );
};