
import React, { useEffect, useRef } from 'react';
import Review from './Reviews';
import './ReviewsList.css';




const ReviewsList = ({ reviews }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const reviewsLength = reviews.length;
        const clonedReviews = [];

        // Clone reviews to make seamless scrolling
        const cloneReviews = () => {
            for (let i = 0; i < reviewsLength * 2; i++) {
                reviews.forEach((review, index) => {
                    const clone = wrapper.children[index].cloneNode(true);
                    wrapper.appendChild(clone);
                    clonedReviews.push(clone);
                });
            }
        };

        cloneReviews();

        // Animate the reviews
        const animate = () => {
            const totalWidth = wrapper.scrollWidth / 2;
            const animationDuration = 100; // Adjust the duration here
            wrapper.style.transition = `transform ${animationDuration}s linear`;
            wrapper.style.transform = `translateX(-${totalWidth}px)`;

            const resetAnimation = () => {
                wrapper.style.transition = 'none';
                wrapper.style.transform = 'translateX(0)';
                setTimeout(() => {
                    wrapper.style.transition = `transform ${animationDuration}s linear`;
                    wrapper.style.transform = `translateX(-${totalWidth}px)`;
                }, 50);
            };

            wrapper.addEventListener('transitionend', resetAnimation);

            return () => {
                wrapper.removeEventListener('transitionend', resetAnimation);
            };
        };

        animate();
    }, [reviews]);

    return (
        <div className="reviews-container">
            <div className="reviews-wrapper" ref={wrapperRef}>
                {reviews.map((review, index) => (
                    <Review key={index} stars={review.stars} comment={review.comment} />
                ))}
            </div>
        </div>
    );
};

export default ReviewsList;
