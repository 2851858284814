import React, { useState, useEffect } from 'react';
import './Stepper.css';

const Stepper = () => {
  const steps = [
    { number: 1, title: 'STEP 1', description: 'Upload', image: '/img/stepper-upload.png' },
    { number: 2, title: 'STEP 2', description: 'Analysis', image: '/img/stepper-analysis.png' },
    { number: 3, title: 'STEP 3', description: 'Review', image: '/img/stepper-review.png' },
    { number: 4, title: 'STEP 4', description: 'Export', image: '/img/stepper-export.png' }
  ];

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [steps.length]);

  const handleStepClick = (index) => {
    setCurrentStep(index);
  };

  return (
    <div className="stepper-container">
      <div className="steps">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div
              className="step"
              onClick={() => handleStepClick(index)}
            >
              <div className={`step-number ${index === currentStep ? 'active' : ''}`}>
                {index === currentStep ? '✔' : step.number}
              </div>
              <div className="step-details">
                <div className="step-title">{step.title}</div>
                <div className="step-description">{step.description}</div>
              </div>
            </div>
            {index < steps.length - 1 && <div className="step-line"></div>}
          </React.Fragment>
        ))}
      </div>
      <div className="image-container">
        <div className="image-box">
          <img
            src={steps[currentStep].image}
            alt={`${steps[currentStep].description} Image`}
            className="image-fade"
          />
        </div>
      </div>
    </div>
  );
};

export default Stepper;