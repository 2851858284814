import React from 'react';
import './Features.css'; 

const featuresData = [
    { icon: '/img/Home_icon_1.png', 
    title: 'Metrics', 
    content: 'We quantify your achievements to provide clear evidence of your success and capabilities' },
    { icon: '/img/Home_icon_2.png', 
    title: 'Technical Skills', 
    content: 'We highlight your expertise to ensure you meet job-specific requirements' },
    { icon: '/img/Home_icon_3.png', 
    title: 'Action', 
    content: 'We emphasize your actions to illustrate your initiative and ability to achieve results' },
    { icon: '/img/Home_icon_4.png',
     title: 'Soft Skills', 
     content: 'We demonstrate your ability to effectively collaborate and manage tasks' },
    { icon: '/img/Home_icon_5.png', 
    title: 'Impact', 
    content: 'We show how your contributions have made a difference in previous roles' },
    { icon: '/img/Home_icon_6.png',
     title: 'Industry', 
     content: 'We ensure your resume align with industry standards and expectations' },
];


const Features = () => {
    return (
        <div className="features">
            {featuresData.map((feature, index) => (
                <div className="feature" key={index}>
                    <img src={feature.icon} alt={`Icon ${index + 1}`} className="icon" />
                    <h3 className="feature_title">{feature.title}</h3>
                    <p className="feature_content">{feature.content}</p>
                </div>
            ))}
        </div>
    );
};

export default Features;
