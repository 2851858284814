import React, { useRef, useState, useEffect } from 'react';
import { RingScore } from "../../components/Ring/Ring_score";
import { useNavigate } from 'react-router-dom';
import CompanyInputBar from '../../components/CompanyInputBar/CompanyInputBar';
import { useAuth0 } from "@auth0/auth0-react";
import { Loading_partial } from '../Loading/Loading_partial';
import { jsPDF } from 'jspdf';

const CoverLetter = ({ data, job_description, company_analysis, original_data, isFieldEmpty, jobPosition, 
    companyName, companyDomain, handleCompanyChange, handleDomainChange, handleJobPositionChange, jobDescriptionText, 
    coverletterApiResponse, setCoverletterApiResponse, application_id, coverletterLoading, setCoverletterLoading, 
    showCoverLetter, setShowCoverLetter}) => {

    const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
    const [selectedStyleOption, setSelectedStyleOption] = useState('Modern');
    const [cultureValue, setCultureValue] = useState('');
    const [personalStory, setPersonalStory] = useState('');

    const sanitizeString = (str) => {
        if (typeof str !== 'string') {
          return str; // Return the original value if it's not a string
        }
        
        return str
          .replace(/[\r\n]+/g, ' ')     // Sanitize newlines
          .replace(/\s+/g, ' ')          // Remove excessive spaces
          .replace(/，/g, ',')           // Replace full-width comma with a regular comma
          .replace(/‐|–|—/g, '-')        // Replace en-dash and em-dash with a regular hyphen
          .trim();
    };

    const areButtonDisable = () => {
        return !companyName || !jobPosition;
    };

    const determineEducationStatus = (currentResumeValue) => {
        // Month mapping
        const monthToNum = {
            "january": 1, "february": 2, "march": 3, "april": 4, "may": 5, "june": 6,
            "july": 7, "august": 8, "september": 9, "october": 10, "november": 11, "december": 12
        };
    
        // Get the current year and month number
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthNum = currentDate.getMonth() + 1; // getMonth returns 0-based index, so +1
    
        // Initialize education status to N/A
        let educationStatus = '';
    
        // Check if there is education data
        let currentEducation = currentResumeValue?.education?.[0] || null;
    
        if (currentEducation) {
            // Extract graduation year and month
            let gradYear = currentEducation?.graduation_year ?? -1;
            let gradMonth = currentEducation?.graduation_month ?? -1;
    
            // Convert gradYear to a number if it's a string
            if (typeof gradYear === 'string') {
                gradYear = parseInt(gradYear, 10);
            }
    
            // Convert gradMonth to a number if it's a string (also handle month names)
            if (typeof gradMonth === 'string') {
                if (isNaN(gradMonth)) {
                    // If it's not a number, try to map it as a month name
                    gradMonth = monthToNum[gradMonth.toLowerCase()] || -1;
                } else {
                    gradMonth = parseInt(gradMonth, 10);
                }
            }
    
            // Determine education status
            if (gradYear === -1 || gradMonth === -1) {
                educationStatus = '';
            } else if (currentYear < gradYear || (currentYear === gradYear && currentMonthNum <= gradMonth)) {
                educationStatus = 'in_progress';
            } else {
                educationStatus = 'graduated';
            }
        }
    
        return educationStatus;
    };

    const extractData = (data) => {
        const workExperience = (data.rendering_data.work || []).map((work) => {
          const { Experience } = work;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        const projectExperience = (data.rendering_data.project || []).map((project) => {
          const { Experience } = project;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        return {
          work_experience: workExperience.length > 0 ? workExperience : null,
          project_experience: projectExperience.length > 0 ? projectExperience : null,
        };
    };

    const sendToServerCoverletter = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();
        // append the required data into the form
        formData.append('application_id', application_id)
        formData.append('first_name', original_data?.personal_info?.first_name || '');
        formData.append('last_name', original_data?.personal_info?.last_name || '');
        formData.append('email', original_data?.personal_info?.email || '');
        formData.append('phone_number', original_data?.personal_info?.phone_number || '');
        formData.append('personal_website_url', original_data?.personal_info?.personal_website_url || '');

        formData.append('education', original_data?.education?.[0]?.university || '');
        formData.append('degree', original_data?.education?.[0]?.degrees || '');
        formData.append('major', original_data?.education?.[0]?.majors || '');
        formData.append('education_status', determineEducationStatus(original_data));

        // formData.append('personal_story', personalStory);
        formData.append('company_name', companyName);
        formData.append('position_title', jobPosition);
        // formData.append('company_value', cultureValue);
        formData.append('style', selectedStyleOption);

        formData.append('job_description', job_description);
        formData.append('job_description_text', jobDescriptionText);
        formData.append('cl_company_domain', companyDomain);
        const resumeObj = extractData(data);
        resumeObj.skills = original_data.skills;
        formData.append('resume_obj', JSON.stringify(resumeObj));
        formData.append('company_value', cultureValue);
        formData.append('personal_story', personalStory);
    
        //start loading
        setCoverletterLoading(true);
        // send a POST request
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        console.log('Send POST request to API')
        fetch('/resumasterapi/revise_coverletter_in_resume', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
          body: formData
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          return response.json();
        }).then(data => {
          setCoverletterApiResponse(data.cover_letter);
          setCoverletterLoading(false);
          setShowCoverLetter(true);
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setCoverletterLoading(false);
          alert(error.message);  // Displaying the error message to the user
        });
    }

    const handleDelete = () => {
        setCoverletterApiResponse(null);
        setShowCoverLetter(false);
    };

    const handleCopyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(coverletterApiResponse);
          console.log("Text copied to clipboard");
        } catch (err) {
          console.error("Error copying text: ", err);
        }
    };

    const exportStringToPDF = (content) => {
        const doc = new jsPDF({
          format: 'letter',
        });
    
        const marginLeft = 20;
        const marginRight = 20;
        const marginTop = 20;
        const marginBottom = 20;
        const pageWidth = doc.internal.pageSize.width;
        const maxContentWidth = pageWidth - marginLeft - marginRight;
    
        // Add Name section
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        const name = `${original_data.personal_info.first_name} ${original_data.personal_info.last_name}`;
        doc.text(name, marginLeft, marginTop);
    
        // Add some space
        let y = marginTop + 7;
    
        // Add Contacts section
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
    
        let contactInfo = [];
        if (original_data.personal_info.email) contactInfo.push(`• ${sanitizeString(original_data.personal_info.email)}`);
        if (original_data.personal_info.phone_number) contactInfo.push(`• ${sanitizeString(original_data.personal_info.phone_number)}`);
        if (original_data.personal_info.personal_website_url) contactInfo.push(`• ${original_data.personal_info.personal_website_url}`);
    
        let contactLine = '';
        contactInfo.forEach(info => {
          if (doc.getTextWidth(contactLine + info) > maxContentWidth) {
            doc.text(contactLine.trim(), marginLeft, y);
            y += 6;
            contactLine = info + '   ';
          } else {
            contactLine += info + '   ';
          }
        });
        if (contactLine.trim()) {
          doc.text(contactLine.trim(), marginLeft, y);
          y += 6;
        }
    
        // Add some space
        y += 10;
    
        // Add Company section
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        const sanitizedCompany = sanitizeString(companyName);
        doc.text(sanitizedCompany, marginLeft, y);
        y += 10;
    
        // Add Cover Letter content
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        const lines = doc.splitTextToSize(content, maxContentWidth);
    
        const lineHeight = 5;
        const pageHeight = doc.internal.pageSize.height;
        const maxPageHeight = pageHeight - marginBottom;
    
        lines.forEach(line => {
          if (y + lineHeight > maxPageHeight) {
            doc.addPage();
            y = marginTop;
          }
          doc.text(line, marginLeft, y);
          y += lineHeight * 1.15;
        });
    
        let jobPositionPart = jobPosition ? `_${jobPosition}` : '';
        let companyPart = sanitizedCompany ? `_${sanitizedCompany}` : '';
    
        doc.save(`${name}${jobPositionPart}${companyPart}_cover_letter.pdf`);
    };

    return (
        <div className="main-section">
            <div className="notice">
                <div className="experience-header">
                    <h4 style={{marginBottom: "20px"}}>Cover Letter</h4>
                    <button onClick={handleDelete} className="delete-button" style={{marginBottom: '20px'}}>
                        Delete
                    </button>
                </div>
            </div>

            {coverletterLoading ? (
                <div style={{ textAlign: 'center', backgroundColor: '#FFFFFF'}}>
                    <Loading_partial />
                    <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600, marginBottom: '20px'}}>This process will take about 20 seconds while we generate your cover letter...</p>
                </div>
            ) : showCoverLetter ? (
                <div className="description">
                    <div className="img-box" style={{marginBottom: '10px'}}>
                        <img className="copy-img"
                            alt="Copy"
                            src="/img/coverletter_copy.png"
                            style={{width: '24px', height: '24px', cursor: 'pointer', marginRight: '10px'}}
                            onClick={() => handleCopyToClipboard()}
                        />
                        <img    className="popup-export-button"
                                onClick={() => exportStringToPDF(coverletterApiResponse)}
                                style={{width: '24px', height: '24px', cursor: 'pointer'}}
                                src='./img/dashboard_download.png'
                        />
                    </div>
                    <textarea
                        className="coverletter-textarea"
                        value={coverletterApiResponse}
                        onChange={(e) => setCoverletterApiResponse(e.target.value)}
                    />
                </div>
            ) : (
                <div className="description">
                    <label className="form-label">Target Company<span className="starColor">*</span></label>
                    <CompanyInputBar
                                    initialCompanyName={companyName} // Pass initial company name
                                    initialCompanyDomain={companyDomain} // Pass initial company domain (logo URL)
                                    onCompanyChange={handleCompanyChange} // Update company name state
                                    onDomainChange={handleDomainChange} // Update company domain (logo) state
                    />
                    {(!companyName ||isFieldEmpty(companyName)) && (
                        <div className="hint-text">Hint: this content is required.</div>
                    )}

                    <label className="form-label" style={{marginTop: '20px'}}>Target Position<span className="starColor">*</span></label>
                    <input 
                        type="text" 
                        className={`experience-form-input ${jobPosition.trim() === '' ? 'input-error' : ''}`}
                        value={jobPosition || ''} 
                        onChange={(e) => handleJobPositionChange(e.target.value)} 
                    />
                    {(!jobPosition ||isFieldEmpty(jobPosition)) && (
                        <div className="hint-text">Hint: this content is required.</div>
                    )}

                    <label className="form-label" style={{marginTop: '20px'}}>Company Value</label>
                    <div className='description-box'>
                        <span className="description-text">Please tell us about the company's culture and values (Limited to 300 Characters)</span>
                        <span className="description-text counter-text">{`${cultureValue.length}/300`}</span>
                    </div>
                    <textarea className="coverletter-generate-textarea" rows={5}  value={cultureValue} onChange={(e) => setCultureValue(e.target.value)} maxLength={300}/>

                    <label className="form-label" style={{marginTop: '20px'}}>Personal Story</label>
                    <div className='description-box'>
                        <span className="description-text">Please tell us about a personal story that we can mention in your cover letter (Limited to 500 Characters)</span>
                        <span className="description-text counter-text">{`${personalStory.length}/500`}</span>
                    </div>
                    <textarea className="coverletter-generate-textarea" rows={5}  value={personalStory} onChange={(e) => setPersonalStory(e.target.value)} maxLength={500}/>

                    <label className="form-label" style={{marginTop: '20px'}}>Cover Letter Writing Style</label>
                    <div className='option-box' style={{width: '600px'}}>
                        <div className={`option-button ${selectedStyleOption === "Modern" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Modern")}>Modern</div>
                        <div className={`option-button ${selectedStyleOption === "Creative" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Creative")}>Creative</div>
                    </div>

                    <button className='coverletter-generate-button' disabled={areButtonDisable()} onClick={sendToServerCoverletter} style={{marginTop: '20px'}}>
                        <img src="/img/ai-generation-button.png" alt="AI Icon" style={{width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle'}}/>
                        Generate Cover Letter
                    </button>
                    <div className="dashboard-logo-api" style={{ marginTop: '20px' }}>
                      <a href="https://logo.dev" alt="Logo API">Logos provided by Logo.dev</a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CoverLetter;