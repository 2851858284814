import React, { useState, useRef, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { FrameWrapper } from "../../components/FrameWrapper";
import './style_dash_editor.css';
import { Link } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { Loading_partial } from '../Loading/Loading_partial';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
import DashboardExpDetails from "./DashboardExperience";
import DashboardEducation from "./DashboardEducation";

const DashboardEditor = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // state for loading
    const [original_data, setOriginalData] = useState({
        personal_info: location.state?.data.basic_info,
        education: location.state?.data.education,
        skills: location.state?.data.skills,
        certificate: location.state?.data.certificate
    });
    const [selectedExperience, setSelectedExperience] = useState(location.state?.selectedExperienceIndex);
    const [selectedProject, setSelectedProject] = useState(0);
    const [resumeTitle, setResumeTitle] = useState(location.state?.currentResumeName);

    const handleResumeTitleChange = (newTitle) => {
        setResumeTitle(newTitle);
    }
    //experience data
    const convertParseExpToEditor = (workExp) => {
        // Convert work experiences
        const formattedWorkExp = workExp.map(exp => ({
            Title: exp.job_title || '',
            Duration: exp.duration || '',
            Location: exp.location || '',
            Company: exp.company || '',
            Responsibilities: exp.job_summary || [],
            type: 'work'
        }));
        return formattedWorkExp;
    };

    const convertParseProjToEditor = (projExp) => {
        // Convert project experiences
        const formattedProjExp = projExp.map(exp => ({
            Title: exp.project_name || '',
            Duration: exp.duration || '',
            Location: exp.location || '',
            Company: exp.institution || '',
            Responsibilities: exp.project_description || [],
            type: 'project'
        }));
        return formattedProjExp;
    };

    const expDataCopy = convertParseExpToEditor(location.state?.data?.work_experience || []);
    const projDataCopy = convertParseProjToEditor(location.state?.data?.project_experience || []);
    //get the exp data and project data for the editor page
    const [data, setData] = useState(expDataCopy);
    const [projectData, setProjectData] = useState(projDataCopy);


    //info locally needed - original_data info section
    const personal_infor ={
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    }
    const skills=original_data.skills
    const certificates = original_data.certificate;
    const education = original_data.education
   
    // Split data into work experience and project experience

    const [apiResponse, setApiResponse] = useState(null);
    

    const [showExperiences, setShowExperiences] = useState(false); // State to toggle showing experiences in sidebar
    const [showProjects, setShowProjects] = useState(false);
    
    const [selectedSection, setSelectedSection] = useState(location.state?.selectedSection ? location.state.selectedSection : 'personalDetails');

    //important info for resume rendering
    const [resumeInfo, setResumeInfo] = useState({
        personal_info: personal_infor,
        skills: skills,
        education: education,
        certificate: certificates
    });

    //rawInput is for the uncetagorized skills
    const [rawInput, setRawInput] = useState((resumeInfo.skills || []).join(', '));

    const handleSkillsChange = (updatedSkills) => {
        setRawInput(updatedSkills);  // Store the raw input temporarily
    };

    const handleSectionClick = (section) => {
        setSelectedSection(section);
        if (section !== 'experiences') {
            setShowExperiences(false)
        }
        if (section !== 'projects') {
            setShowProjects(false);
        }
        window.scrollTo(0, 0);
    };

    const getButtonStyle = (sectionName) => {
        let buttonStyle = {
            backgroundColor: selectedSection === sectionName ? '#F0EDFF' : 'transparent',
            color: selectedSection === sectionName ? '#6750A4' : '',
            borderRadius: selectedSection === sectionName ? '40px' : '40px',
            fontWeight: selectedSection === sectionName ? '600' : '',
            // Add red border based on the section's fill status
            border: (
                (sectionName === 'education' && !isEducationFieldsFilled) ||  // Red border for incomplete education section
                (sectionName === 'experiences' && !isExperienceFieldsFilled) ||  // Red border for incomplete experiences section
                (sectionName === 'projects' && !isProjectFieldFilled) ||
                (sectionName === 'personalDetails' && (!resumeInfo.personal_info['First Name'] || !resumeInfo.personal_info['Last Name'])) // Red border for missing personal info)
            ) 
            ? '1px solid #B00000'  // Red border for unfilled section
            : 'none',              // No border by default
        };
    
        return buttonStyle;
    };

    const getExperienceStyle = (index) => ({
        // backgroundColor: selectedExperience === index ? '#FAF9FF' : '',
        fontWeight: selectedExperience === index ? '600' : '',
        borderRadius: selectedExperience === index ? '40px' : '40px',
        color: selectedExperience === index ? 'black' : ''
    });

    const getProjectStyle = (index) => ({
        // backgroundColor: selectedProject === index ? '#FAF9FF' : '',
        fontWeight: selectedProject === index ? '600' : '',
        borderRadius: selectedProject === index ? '40px' : '40px',
        color: selectedProject === index ? 'black' : ''
    });

    // Function to toggle showing experiences in sidebar
    const toggleExperiences = () => {
        setShowExperiences(!showExperiences);
    };
    // Function to toggle showing projects in sidebar
    const toggleProjects = () => {
        setShowProjects(!showProjects);
    };
    // Function to check experience fields
    const checkExperienceFields = (experienceDetails) => {
        const requiredFields = ['Title', 'Company', 'Duration'];
        return requiredFields.every(field => experienceDetails[field]);
    };
    
    const handleExperienceChange = (current_experience, index) => {
        setData(prevData => {
          // Create a new array with the updated value at the specified index
          const newRenderingData = [...prevData];
          newRenderingData[index] = current_experience;
          // Return the updated array directly since there's no need for a wrapper object
          return newRenderingData;
        });
        
      };

      const handleProjectChange = (current_experience, index) => {
        setProjectData(prevData => {
          // Create a new array with the updated value at the specified index
          const newRenderingData = [...prevData];
          newRenderingData[index] = current_experience;
          // Return the updated array directly since there's no need for a wrapper object
          return newRenderingData;
        });
        
      };

    const handleExperienceDelete = (index) => {
        const updatedExperience = data.filter((_, i) => i !== index);

        // Adjust selectedExperience if necessary
        if (selectedExperience >= (updatedExperience.length)) {
            setSelectedExperience(Math.max(updatedExperience.length - 1, 0));
        }
        
        // Update the resumeInfo and originalData states
        setData(updatedExperience);
    };

    const handleProjectDelete = (index) => {
        const updatedExperience = projectData.filter((_, i) => i !== index);

        // Adjust selectedExperience if necessary
        if (selectedProject >= (updatedExperience.length)) {
            setSelectedProject(Math.max(updatedExperience.length - 1, 0));
        }

        // Update the resumeInfo and originalData states
        setProjectData(updatedExperience);
    };

    const handleExperienceAdd = () => {
        // Define a new empty experience object with the same structure
        const newExperience = {
            Title: '',
            Duration: '',
            Location: '',
            Company: '',
            Responsibilities: [''],
            type: 'work' // You can change this to 'project' if it's a project experience
        };
        // Add the new experience to the end of the data array
        // Update the data state and set the selectedExperience to the new index
        setData(prevData => {
            const updatedData = [...prevData, newExperience];
            
            // Set selectedExperience to the index of the new item
            setSelectedExperience(updatedData.length - 1);
            
            return updatedData;
        });   
    };

    const handleProjectAdd = () => {
        // Define a new empty experience object with the same structure
        const newExperience = {
            Title: '',
            Duration: '',
            Location: '',
            Company: '',
            Responsibilities: [''],
            type: 'project' // You can change this to 'project' if it's a project experience
        };
        // Add the new experience to the end of the data array
        // Update the data state and set the selectedExperience to the new index
        setProjectData(prevData => {
            const updatedData = [...prevData, newExperience];
            
            // Set selectedExperience to the index of the new item
            setSelectedProject(updatedData.length - 1);
            
            return updatedData;
        });   
    };

    const handlePersonalInfoChange = (key, value) => {
        setResumeInfo((prevState) => ({
            ...prevState,
            personal_info: {
                ...prevState.personal_info,
                [key]: value
            }
        }));
        if (key === "First Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    first_name: value
                }
            }));
        } else if (key === "Last Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    last_name: value
                }
            }));
        } else if (key === "Email Address") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    email: value
                }
            }));
        } else if (key === "Phone Number") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    phone_number: value
                }
            }));
        } else if (key === "Location") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    location: value
                }
            }));
        } else if (key === "LinkedIn/Personal Website URL") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    personal_website_url: value
                }
            }));
        }
    };

    const handleEducationChange = (updatedEducation) => {
        setResumeInfo((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleEducationDelete = (index) => {
        const updatedEducation = resumeInfo.education.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleAddEducation = () => {
        const neweducation = [...(resumeInfo.education || []), { GPA: '', degrees: '', graduation_month: '', graduation_year: '', majors: '', university: '', university_start_month: '', university_start_year: '' }];

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            education: neweducation,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            education: neweducation,
        }));
    };

    const processSkills = () => {
        const new_skills_list = rawInput
            .split(',')
            .map(skill => skill.trim())
            .filter(skill => skill.length > 0);
    
        setResumeInfo((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));
    
        setOriginalData((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));
    };

    const handleAddCertificate = () => {
        const newCertificates = [...(resumeInfo.certificate || []), { name: '', achieved_date: '' }];

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: newCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: newCertificates,
        }));
    };

    const handleCertificateChange = (index, key, value) => {
        const updatedCertificates = [...resumeInfo.certificate];
        updatedCertificates[index][key] = value;
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };

    const handleDeleteCertificate = (index) => {
        const updatedCertificates = resumeInfo.certificate.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setResumeInfo((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };
    const isFieldEmpty = (value) => value === '';
    const areRequiredFieldsFilled = () => {
        return resumeTitle && resumeInfo.personal_info['First Name']?.trim() &&
            resumeInfo.personal_info['Last Name']?.trim() &&
            isEducationFieldsFilled &&
            isExperienceFieldsFilled &&
            isProjectFieldFilled;

    };
    // State to manage experience fields fill status
    const [isExperienceFieldsFilled, setIsExperienceFieldsFilled] = useState(true);
    // State to track if each project is filled
    const [areExperiencesFilled, setAreExperiencesFilled] = useState([]);
    // Function to check if each project in projectData is filled
    const checkIfExperiencesFilled = () => {
        return data.map(exp => checkExperienceFields(exp));  // Returns an array of booleans
    };

    // State to manage projectfields fill status
    const [isProjectFieldFilled, setIsProjectFieldFilled] = useState(true);
    // State to track if each project is filled
    const [areProjectsFilled, setAreProjectsFilled] = useState([]);

    // Function to check if each project in projectData is filled
    const checkIfProjectsFilled = () => {
        return projectData.map(exp => checkExperienceFields(exp));  // Returns an array of booleans
    };
    
    // State to manage education fields fill status
    const [isEducationFieldsFilled, setIsEducationFieldsFilled] = useState(true);

    // Function to check education fields
    const checkEducationFields = (education) => {
        if (!education || !Array.isArray(education)) {
            return true; // Return false if education is null, undefined, or not an array
        }
        return education.every(edu => edu.university && edu.degrees && edu.university_start_month && edu.university_start_year && edu.graduation_month && edu.graduation_year);
    };

    const handleExperienceClick = (index) => {
        setSelectedExperience(index);

        // Perform the check synchronously
        const allExperiencesFilled = data?.every(exp => checkExperienceFields(exp));
        // Update the state
        setIsExperienceFieldsFilled(allExperiencesFilled);
    };

    const handleProjectClick = (index) => {
        setSelectedProject(index);

        // Perform the check synchronously
        const allProjectsFilled = projectData?.every(exp => checkExperienceFields(exp));
        // Update the state
        setIsProjectFieldFilled(allProjectsFilled);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        if (resumeInfo.skills) {
            setRawInput((resumeInfo.skills || []).join(', '));
        }
    }, [resumeInfo.skills]);

    useEffect(() => {
        // Check education and experience fields when data changes
        const educationFilled = checkEducationFields(resumeInfo.education);
        const allexperienceFilled = data?.every(exp => checkExperienceFields(exp));
        const allprojectsFilled = projectData?.every(exp => checkExperienceFields(exp));

        setIsEducationFieldsFilled(educationFilled);
        setIsExperienceFieldsFilled(allexperienceFilled);
        setIsProjectFieldFilled(allprojectsFilled);

    }, [resumeInfo.education, data, isExperienceFieldsFilled, isProjectFieldFilled]);

    useEffect(() => {
        if (selectedSection === 'experiences') {
          setShowExperiences(true);
        }
        if (selectedSection === 'projects') {
          setShowProjects(true);
        }
      }, [selectedSection]); // The effect will trigger whenever selectedSection changes

      useEffect(() => {
        const filledStatus = checkIfProjectsFilled();
        setAreProjectsFilled(filledStatus);
    }, [projectData]);

    useEffect(() => {
        const filledStatus = checkIfExperiencesFilled();
        setAreExperiencesFilled(filledStatus);
    }, [data]);

    const convertEditorDataToJSON = () => {
        let context = {};
        context["Resume_name"] = resumeTitle;
        context["Resume_info"] = {};
        context["Resume_info"]["basic_info"] = original_data.personal_info;
        context["Resume_info"]["education"] = original_data.education;
        context["Resume_info"]["skills"] = original_data.skills;
        context["Resume_info"]["certificate"] = original_data.certificate;
        context["Resume_info"]["work_experience"] = [];
        context["Resume_info"]["project_experience"] = [];
        
        data.forEach(exp => {
            if (exp.type === 'work') {
                // Convert to work experience format
                let workExperience = {
                    "job_title": exp.Title || '',
                    "company": exp.Company || '',
                    "location": exp.Location || '',
                    "duration": exp.Duration || '',
                    "job_summary": exp.Responsibilities || []
                };
                context["Resume_info"]["work_experience"].push(workExperience);
            }
        });

        projectData.forEach(exp => {
            if (exp.type === 'project') {
                // Convert to project experience format
                let projectExperience = {
                    "project_name": exp.Title || '',
                    "institution": exp.Company || '',
                    "location": exp.Location || '',
                    "duration": exp.Duration || '',
                    "project_description": exp.Responsibilities || []
                };
                context["Resume_info"]["project_experience"].push(projectExperience);
            }
        });

        return context;
    };

    const sendToServer_db = async (navigateTo) => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        //---------------------------------auhentication code----------------------------------------
        const current_resume = convertEditorDataToJSON();
        const resume_category = 'default';
        //start loading
        setLoading(true);
        // create a FormData object
        const formData = new FormData();
        formData.append('db_json_store', JSON.stringify(current_resume));
        formData.append('resume_category', resume_category);
    
        // send a POST request
        console.log('Send POST request to API')
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/save_dashboard_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            setLoading(false);
            navigate(navigateTo);
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoading(false);
    
            alert(error.message);  // Displaying the error message to the user
        });
    };


    return (
        isAuthenticated ? (
            <div className="resu-results">
                <Helmet>
                    {/* <!-- Google tag (gtag.js) --> */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                        <script>
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-F812QPTDD9');
                        `}
                        </script>
                    <title>ResumeResults - ResuMaster</title>
                    <meta property="og:title" content="Results - ResuMaster" />
                    <body className="result_page" />
                    <script src="//embed.typeform.com/next/embed.js"></script>
                </Helmet>
                {
                    isAuthenticated ? (
                        <Navigation_loggedin className="navigation-2202" />
                    ) : (
                        <Navigation className="navigation-2202" />
                    )
                }

                <div className="contents">
                   
                    < div className="main-box">      
                        <Link to="/Dashboard" className="dashboard-editor-back-button">
                            <img className="back-button-img" src='/img/chevron-left.png' />
                            <div className="back-button-text">Back</div>
                        </Link>                  
                        {selectedSection === 'personalDetails' && (
                            <div className="personal-content">

                                {resumeInfo.personal_info && Object.entries(resumeInfo.personal_info).map(([key, value]) => (
                                    <div key={key} className="detail-item-personal">
                                        <label className="form-label">
                                            {key}
                                            {(key === "First Name" || key === "Last Name") && <span className="starColor">*</span>}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-input"
                                            value={value || ''}
                                            onChange={(e) => handlePersonalInfoChange(key, e.target.value)}
                                        />
                                        {((key === "First Name" || key === "Last Name") && !(value?.trim())) && (
                                            <div className="hint-text">Hint: this content is required.</div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        )}
                        {selectedSection === 'education' && (
                            <DashboardEducation education={resumeInfo.education}
                                       onEducationChange={handleEducationChange} 
                                       handleEducationDelete={handleEducationDelete}
                                       handleAddEducation={handleAddEducation}
                                       isFieldEmpty={isFieldEmpty} 
                                       checkEducationFields={checkEducationFields}
                                       setIsEducationFieldsFilled={setIsEducationFieldsFilled}
                        />
                        )}
                        {selectedSection === 'experiences' && (
                            <DashboardExpDetails
                                data={data}
                                original_data = {original_data}
                                selectedExperience={selectedExperience}
                                handleExperienceChange = {handleExperienceChange}
                                handleExperienceDelete = {handleExperienceDelete}
                                isFieldEmpty={isFieldEmpty}
                                checkExperienceFields={checkExperienceFields}
                                setIsExperienceFieldsFilled={setIsExperienceFieldsFilled}
                            />
                        )}
                        {selectedSection === 'projects' && (
                            <DashboardExpDetails
                                data={projectData}
                                original_data = {original_data}
                                selectedExperience={selectedProject}
                                handleExperienceChange = {handleProjectChange}
                                handleExperienceDelete = {handleProjectDelete}
                                isFieldEmpty={isFieldEmpty}
                                checkExperienceFields={checkExperienceFields}
                                setIsExperienceFieldsFilled={setIsProjectFieldFilled}
                            />
                        )}
                        {selectedSection === 'skills' && (
                            <div className="skills-container">
                                <div className="detail-item-skills">
                                    {
                                        <>
                                            <label className="form-label">Skills (edit in a common-separated format)</label>
                                            <textarea
                                                className="form-input"
                                                value={rawInput}
                                                onChange={(e) => handleSkillsChange(e.target.value)}
                                                onBlur={processSkills}  // Process skills when the textarea loses focus
                                                rows={10}
                                            />
                                        </>
                                    }
                                </div>
                            </div>                         
                        )}
                        {selectedSection === 'certificates' && (
                        <>
                            {resumeInfo.certificate && resumeInfo.certificate.length > 0 && resumeInfo.certificate.map((certificate_element, index) => (
                                <>
                                    <div className="certificate-header">
                                        <div className="certificate-title">
                                            Certificate {index + 1}
                                        </div>
                                        <button onClick={() => handleDeleteCertificate(index)} className="delete-button">
                                            Delete
                                        </button>
                                    </div>
                                    <div className="certificate-container" key={index}>
                                        <label className="form-label">Certificate Name</label>
                                        <input
                                            type="text"
                                            className="experience-form-input"
                                            value={certificate_element.name || ''}
                                            onChange={(e) => handleCertificateChange(index, 'name', e.target.value)}
                                            maxLength={80}
                                        />
                                        <label className="form-label">Achieved Date</label>
                                        <input
                                            type="text"
                                            className="experience-form-input"
                                            value={certificate_element.achieved_date || ''}
                                            onChange={(e) => handleCertificateChange(index, 'achieved_date', e.target.value)}
                                            maxLength={20}
                                        />
                                    </div>
                                </>
                            ))}

                                <div className="button-container">
                                    <button className="add-certificate-button" onClick={handleAddCertificate}>
                                        Add Certificate
                                    </button>
                                </div>
                                    </>
                                )}
                    </div>

                    <div className="sidebar">
                        <input
                            type="text"
                            className="experience-form-input"
                            value= {resumeTitle}
                            onChange={(e) => handleResumeTitleChange(e.target.value)}
                            maxLength={25}
                        />
                        {!resumeTitle && (
                            <p className="hint-message">Hint: this content is required.</p>
                        )}

                        <div className="dashboard-sidebar-continue-revision-section">
                            <button className="dashboard-continue-button" disabled={!areRequiredFieldsFilled()} onClick={() => sendToServer_db('/DashboardRevision')}>Continue to Revision</button>
                            {!areRequiredFieldsFilled() && (
                                    <p className="hint-message">Some required fields are incomplete. Please review and complete them as indicated.</p>
                            )}
                        </div>
                        
                        <div className="side-list">
                            <button
                                onClick={() => handleSectionClick('personalDetails')}
                                style={getButtonStyle('personalDetails')}
                                className={areRequiredFieldsFilled() ? '' : 'experience-button'}
                            >
                                <img 
                                    alt="resume_personalinformation" 
                                    src={selectedSection === 'personalDetails' ? "/img/resume_personal_purple.png" : "/img/resume_personalinformation.png"} 
                                />
                                Personal Details
                            </button>

                            <button 
                                onClick={() => handleSectionClick('education')}
                                style={getButtonStyle('education')}
                            >
                                <img alt="resume_educationn" src={selectedSection === 'education' ? "/img/resume_purple_education.png" :"/img/resume_education.png" }/>
                                Education</button>

                            <button  onClick={()=>{
                                handleSectionClick('experiences');
                                toggleExperiences();
                            }}
                            style={getButtonStyle('experiences')}
                            >
                                <img alt="resume_experience" src={selectedSection === 'experiences' ? "/img/resume_purple_experience.png" : "/img/resume_experience.png" }/>
                                Experience</button>
                            <ul>
                                {showExperiences && data && data.map((experience, index) => (
                                    <li key={index}>
                                        <button onClick={() => { handleExperienceClick(index) }} 
                                            style={{
                                                ...getExperienceStyle(index), // Apply the base style
                                                border: areExperiencesFilled[index] === false ? '1px solid #B00000' : '', // Add red border if project not filled
                                            }}>
                                            {(experience.Title || '')} at {(experience.Company || '')}
                                        </button>
                                    </li>
                                ))}
                                {showExperiences && <button className="add" onClick={handleExperienceAdd}>+ Add</button>}
                            </ul>

                            <button  onClick={()=>{
                                handleSectionClick('projects');
                                toggleProjects();
                            }}
                            style={getButtonStyle('projects')}
                            >
                                <img alt="resume_experience" src={selectedSection === 'projects' ? "/img/project_icon_purple.png" : "/img/resume_project.png" }/>
                                Project</button>
                            <ul>
                                {showProjects && projectData && projectData.map((experience, index) => (
                                    <li key={index}>
                                        <button onClick={() => { handleProjectClick(index) }} 
                                            style={{
                                                ...getProjectStyle(index), // Apply the base style
                                                border: areProjectsFilled[index] === false ? '1px solid #B00000' : '', // Add red border if project not filled
                                            }}>
                                            {(experience.Title || '')} at {(experience.Company || '')}
                                        </button>
                                    </li>
                                ))}
                                {showProjects && <button className="add" onClick={handleProjectAdd}>+ Add</button>}
                            </ul>
                           
                            <button 
                                onClick={() => handleSectionClick('skills')}
                                style={getButtonStyle('skills')}
                            >
                                <img alt="resume_skills" src={selectedSection === 'skills' ? "/img/resume_purple_skills.png" : "/img/resume_skills.png" }/>
                                Skills</button>

                            <button 
                                onClick={() => handleSectionClick('certificates')}
                                style={getButtonStyle('certificates')}
                            >
                                <img alt="certificate" src={selectedSection === 'certificates' ? "/img/resume_purple_certificate.png" : "/img/resume_certificate.png" }/>
                                Certifications</button>
                        </div>
                        <div className="dashboard-sidebar-buttons-section">
                            <button className="dashboard-save-button" disabled={!areRequiredFieldsFilled()} onClick={() => sendToServer_db('/Dashboard')}>Save Resume for Future Use</button>
                        </div>
                    </div>
                </div>
            </div >
        ) : (
            <div>Please log in first</div>
        ))
};

export default DashboardEditor;