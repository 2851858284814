import React, { useEffect, useState, useMemo } from 'react';
import { PDFDocument, StandardFonts, rgb, setLineHeight } from 'pdf-lib';
import { Helmet } from 'react-helmet';
import { Navigation } from "../../components/Navigation";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CompanyInputBar from '../../components/CompanyInputBar/CompanyInputBar';
import { NavigationLoggedinEditor } from "../../components/Navigation_loggedin_editor/NavigationLoggedinEditor";
import { ModalCloseEditor } from "../../components/ModalCloseEditor/ModalCloseEditor";
import { TemplateToolBar } from "../../components/TemplateToolBar/TemplateToolBar";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
//---------------------------------authentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------authentication code----------------------------------------
import './style.css';

export const PdfGenerator = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [pdfBlob, setPdfBlob] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(1); // Set default selected template to 1
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // state for loading
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // for real dynamic data
    const [exp_data, setExpData] = useState(location.state?.data.rendering_data);
    const [original_data, setOriginalData] = useState(location.state?.original_data);
    const [jdKeywords, setJdKeywords] = useState(location.state?.jd_keywords);
    const combinedSkills = [...new Set([...(original_data.skills || []), ...(jdKeywords || [])])];
    const [showBoldKeywords, setShowBoldKeywords] = useState(true);
    const [executive_summary, setExecutiveSummary] = useState(location.state?.executive_summary);
    const [isSkillsCategorized, setIsSkillsCategorized] = useState(location.state?.isSkillsCategorized);
    const [categorizedSkills, setCategorizedSkills] = useState(location.state?.categorizedSkills);
    const [jobPosition, setJobPosition] = useState(location.state?.jobPosition);
    const [companyName, setCompanyName] = useState(location.state?.companyName || '');
    const [companyDomain, setCompanyDomain] = useState(location.state?.companyDomain || '');
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);
    const application_id = location.state?.application_id;
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, Zoom } = zoomPluginInstance;
    // console.log(original_data);
    // console.log(exp_data);
    // console.log(location.state?.job_description);

    const handleCompanyChange = (name) => {
        setCompanyName(name);
    };

    const handleDomainChange = (domain) => {
        setCompanyDomain(domain);
    };
    const monthMap = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    };
    // console.log(original_data);
    // console.log(executive_summary);
    // console.log(exp_data);

    useEffect(() => {
        // Function to modify the style of popovers
        const modifyPopoversStyle = () => {
          // Select all elements that match the ID pattern
          const popovers = document.querySelectorAll(
            '[id^="rpv-core__popover-body-inner"]'
          );
    
          popovers.forEach((popover) => {
            // Check if the element is an HTMLElement and modify its style
            if (popover instanceof HTMLElement && popover.style.maxHeight === "0px") {
              popover.style.maxHeight = "none"; // Unset the max-height
            }
          });
        };
    
        // Create a new MutationObserver instance
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              modifyPopoversStyle();
            }
          });
        });
    
        // Start observing the document body for added nodes
        observer.observe(document.body, { childList: true, subtree: true });
    
        // Cleanup function
        return () => observer.disconnect();
      }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    //info locally needed - original_data info section
    const personal_infor ={
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    }
    const skills=original_data.skills
    const certificates = original_data.certificate;
    const education = original_data.education

    // Split data into work experience and project experience
    const work_experience = exp_data.work;
    const project_experience = exp_data.project;


    //important info for resume rendering
    const [data, setData] = useState({
        personal_info: personal_infor,
        executive_summary: executive_summary,
        skills: skills,
        education: education,
        certificate: certificates,
        revised_work_exp: work_experience,
        revised_proj_exp: project_experience
    });

  const name = (data.personal_info['First Name'] || '') + " " + (data.personal_info['Last Name'] || '');
  const email = data.personal_info['Email Address'] || '';
  const phone_number = data.personal_info['Phone Number'] || '';
  const personal_website = data.personal_info['LinkedIn/Personal Website URL'] || '';
  const user_location = data.personal_info['Location'] || '';
  const summary = data.executive_summary;
  const education_list = data.education;
  const revisedWorkExp = data.revised_work_exp ?? [];
  const revisedProjExp = data.revised_proj_exp ?? [];
  const experiences = [...revisedWorkExp, ...revisedProjExp];

  // toolbar section
  const [fontsize, setFontSize] = useState(location.state?.fontsize);
  const [name_fontsize, setNameFontSize] = useState(location.state?.name_fontsize);
  const [sectionSpacing, setSectionSpacing] = useState(location.state?.sectionSpacing);
  const [sectionBetweenLine, setSectionBetweenLine] = useState(location.state?.sectionBetweenLine);
  const [lineHeightSpacing, setLineHeightSpacing] = useState(location.state?.lineHeightSpacing);
  // template 1 special toolbar variables
  const [template_1_section_header, setTemplate1SectionHeader] = useState(location.state?.template_1_section_header);
  // template 3 special toolbar variables
  const [name_fontsize_template_3, setNameFontsizeTemplate3] = useState(location.state?.name_fontsize_template_3);

  const decreaseFontSize = () => {
    setFontSize(prevFontSize => Math.max(prevFontSize - 0.5, 8)); // Set a minimum font size
    setNameFontSize(prevFontSize => Math.max(prevFontSize - 0.5, 8))
    setTemplate1SectionHeader(prevFontSize => Math.max(prevFontSize - 0.5, 8))
    setNameFontsizeTemplate3(prevFontSize => Math.max(prevFontSize - 0.5, 8))
  };

  const increaseFontSize = () => {
    setFontSize(prevFontSize => prevFontSize + 0.5);
    setNameFontSize(prevFontSize => prevFontSize + 0.5)
    setTemplate1SectionHeader(prevFontSize => prevFontSize + 0.5)
    setNameFontsizeTemplate3(prevFontSize => prevFontSize + 0.5)
  };

  const generateTemplate1 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const createPage = () => {
        const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
        return page;
      };

      let page = createPage();
      let { width, height } = page.getSize();

      const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
        const textToDraw = text || '';
        // Ensure text is not null or undefined
        const sanitizedText = textToDraw
                .replace(/，/g, ',')          // Replace full-width comma with a regular comma
                .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
                .trim();
    
        if (y < 50) {
            page = createPage();
            y = height - 50;
        }
        page.drawText(sanitizedText, { x, y, size, font, color });
        return y;
    };

      const drawSectionHeader = (text, y) => {
        y = drawText(text, 50, y, template_1_section_header, timesRomanBoldFont, rgb(0, 0, 0));
        return y - lineHeightSpacing - 4;
      };

      const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color) => {
        // Split the line into words by spaces
        const words = line.split(' ');
        let currentX = x;

        words.forEach((word, index) => {
            let currentFont = regularFont;
            let matchingSkill = null;
            let hasPunctuation = false;

            // Remove punctuation at the end for matching purposes but preserve it for drawing
            const trimmedWord = word.replace(/[,.!?]+$/, ''); // Remove punctuation at the end
            const punctuation = word.slice(trimmedWord.length); // Store the punctuation if present

            // Check if the word exactly matches any skill in the combinedSkills list (case-sensitive)
            combinedSkills.forEach(skill => {
            if (word === skill || (trimmedWord === skill && punctuation)) {
                matchingSkill = skill;
                if (punctuation) hasPunctuation = true;
            }
            });

            // Check if y is too low, create a new page if necessary
            if (y < 50) {
            page = createPage();
            y = height - 50;
            currentX = x; // Reset x position when starting a new page
            }

            // If there's a matching skill and punctuation, bold only the matching part
            if (matchingSkill && hasPunctuation) {
            // Draw the matching part (bold)
            page.drawText(trimmedWord, {
                x: currentX,
                y,
                size,
                font: boldFont,
                color,
            });
            currentX += boldFont.widthOfTextAtSize(trimmedWord, size);

            // Draw the punctuation (regular)
            page.drawText(punctuation, {
                x: currentX,
                y,
                size,
                font: regularFont,
                color,
            });
            currentX += regularFont.widthOfTextAtSize(punctuation, size);

            } else if (matchingSkill) {
            // Draw the entire word in bold if it matches without punctuation
            page.drawText(word, {
                x: currentX,
                y,
                size,
                font: boldFont,
                color,
            });
            currentX += boldFont.widthOfTextAtSize(word, size);

            } else {
            // Draw the entire word in regular font if no match
            page.drawText(word, {
                x: currentX,
                y,
                size,
                font: regularFont,
                color,
            });
            currentX += regularFont.widthOfTextAtSize(word, size);
            }

            // Add space after the word unless it's the last word or followed by standalone punctuation
            if (index < words.length - 1) {
                currentX += regularFont.widthOfTextAtSize(' ', size); // Add space between words, except when punctuation follows
            }
        });

        return y;
      };
      
      const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
        const bullet = '• ';
        const bulletWidth = font.widthOfTextAtSize(bullet, size);
        const indent = x + bulletWidth; // Position for the subsequent lines
        const words = text.split(' ');
        let line = '';
        let firstLine = true;

        words.forEach(word => {
          const testLine = line + word + ' ';
          const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

          if (testLineWidth < maxWidth) {
            line = testLine;
          } else {
            y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
          }
        });

        y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
        return y - lineHeightSpacing - 4;
      };

      const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
        if (!text) return y;
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
        .replace(/\s+/g, ' ')      // Remove excessive spaces
        .replace(/，/g, ',')        // Replace full-width comma with a regular comma
        .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
        .trim();
        const bullet = '• ';
        const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
        const indent = x + bulletWidth; // Position for the subsequent lines after the bullet
        const words = sanitizedText.split(' ');
        let line = '';
        let firstLine = true;
      
        words.forEach(word => {
          const isSkill = combinedSkills.includes(word); // Check if the word is a skill
          const currentFont = isSkill ? boldFont : regularFont; // Use bold font if it's a skill
      
          const testLine = line + word + ' ';
          const testLineWidth = currentFont.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);
      
          if (testLineWidth < maxWidth) {
            line = testLine;
          } else {
            // Draw the current line with bullet for the first line
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
          }
        });
      
        // Draw the last line with bullet if it's the first line, and handle font styles
        y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
        return y - lineHeightSpacing - 4;
      };

      const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        // Return immediately if text is empty or null
        if (!text) return y;
        // Sanitize the input text by removing any carriage return or newline characters
        // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
        .replace(/\s+/g, ' ')      // Remove excessive spaces
        .replace(/，/g, ',')        // Replace full-width comma with a regular comma
        .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
        .trim();
      
        const words = sanitizedText.split(' ');
        let line = '';
        let lines = [];
      
        words.forEach(word => {
          // Check if adding the next word exceeds the maxWidth
          if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            // If it exceeds, push the current line to the array and start a new line
            lines.push(line.trim()); // Trim extra space at the end of the line
            line = word + ' ';
          }
        });
      
        // Push the last line
        lines.push(line.trim());
      
        // Draw each line
        lines.forEach((line, index) => {
          y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          y -= (lineHeightSpacing + 4); // Adjust the y position for the next line
        });
      
        return y;
      };

      const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        if (!text) return y;
        // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
        .replace(/\s+/g, ' ')      // Remove excessive spaces
        .replace(/，/g, ',')        // Replace full-width comma with a regular comma
        .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
        .trim();
        const words = sanitizedText.split(' ');
        let line = '';
        let lines = [];
        words.forEach(word => {
          if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
            lines.push(line);
            line = word + ' ';
          } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
            line += word + ' ';
          } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            lines.push(line);
            line = word + ' ';
          }
        });
        lines.push(line);
        lines.forEach((line, index) => {
          if (index === 0) {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          } else {
            y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
          }
          y -= (lineHeightSpacing + 4);
        });
        return y;
      };

      const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
        if (!text) return y;
        // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
        .replace(/\s+/g, ' ')      // Remove excessive spaces
        .replace(/，/g, ',')        // Replace full-width comma with a regular comma
        .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
        .trim();
        const words = sanitizedText.split(' ');
        let line = '';
        let lines = [];
        words.forEach(word => {
          if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
            lines.push(line);
            line = word + ' ';
          } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
            line += word + ' ';
          } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
            line += word + ' ';
          } else {
            lines.push(line);
            line = word + ' ';
          }
        });
        lines.push(line);
        lines.forEach((line, index) => {
          if (index === 0) {
            y = drawText(line, x, y, size, font, rgb(0, 0, 0));
          } else {
            y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
          }
        });
        return y;
      };

      // Header
      // Construct the header information dynamically
        const parts = [email, phone_number, user_location, personal_website].filter(item => item);
        const headerInfo = parts.join(' | ');

        // Header
        let yPosition = height - 40;
        yPosition = drawText(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);

        // Only draw the header info if it's not empty
        if (headerInfo) {
            yPosition = drawText(headerInfo, 50, yPosition - sectionSpacing, fontsize);
        }

      // Summary
      if (summary) {
        yPosition = drawTextWithWrapping(summary, 50, yPosition - sectionSpacing, fontsize, timesRomanFont);
        // Add extra space after the summary
        yPosition += (lineHeightSpacing + 4);
        yPosition -= sectionSpacing;
      } else {
        yPosition -= sectionSpacing;
      }

      // Education
      if (education_list && education_list.length > 0) {
        yPosition = drawSectionHeader('Education', yPosition);
        education_list.forEach((edu, index) => {
            yPosition = drawText(edu.university, 50, yPosition, fontsize, timesRomanBoldFont);

            // Calculate the position for the duration to be right-aligned
            const durationText = `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year} - ${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}`;
            const durationTextWidth = timesRomanFont.widthOfTextAtSize(durationText, fontsize);
            yPosition = drawText(durationText, width - durationTextWidth - 50, yPosition, fontsize);
            
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            yPosition = drawText(degree_major_text, 50, yPosition - lineHeightSpacing - 4, fontsize);

            if (edu.GPA) {
                yPosition = drawText(`GPA: ${edu.GPA}`, 50, yPosition - lineHeightSpacing - 4, fontsize);
            }

            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition -= sectionSpacing;
              } else {
                yPosition -= sectionSpacing;
              }
        });
      }
      

      // Experience
      if (experiences && experiences.length > 0) {
        yPosition = drawSectionHeader('Experience', yPosition);

        experiences.forEach(exp => {
            const title_text = exp.Experience.Title + " at " + exp.Experience.Company;
            yPosition = drawText(title_text, 50, yPosition, fontsize, timesRomanBoldFont);
            if (exp.Experience.Location) {
                yPosition = drawText(exp.Experience.Location, 50, yPosition - lineHeightSpacing - 4, fontsize);
            }
            if (exp.Experience.Duration) {
                const expDurationTextWidth = timesRomanFont.widthOfTextAtSize(exp.Experience.Duration, fontsize);
                yPosition = drawText(exp.Experience.Duration, width - expDurationTextWidth - 50, yPosition, fontsize);
            }
            yPosition -= (lineHeightSpacing + 4);
            exp.Experience.Responsibilities.forEach(resp => {
                if (showBoldKeywords) {
                    yPosition = drawBulletedText(resp, 50, yPosition);
                } else {
                    yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
                }
            });
            yPosition += (lineHeightSpacing + 4);
            yPosition -= sectionSpacing;
        });
      }
      
      // Skills
      if (skills && skills.length > 0) {
        // Add 20 vertical spacing before Skills section
        if (isSkillsCategorized) {
            yPosition = drawSectionHeader('Skills', yPosition);
            let lastValidIndex = -1;
            // First, identify the last valid index
            Object.keys(categorizedSkills).forEach((key, index) => {
                if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                    lastValidIndex = index;
                }
            });

            Object.keys(categorizedSkills).forEach((key, index) => {
                // Check if the value is an empty list or null
                if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                    return; // Skip to the next iteration
                }

                let skillsText = categorizedSkills[key].join(', ');
                keyText = key + ': ';
                yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
                yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

                // Check if the current key is the last valid element in categorizedSkills
                if (index === lastValidIndex) {
                    yPosition += (lineHeightSpacing + 4);
                    yPosition -= sectionSpacing;
                }
            });
        } else {
            yPosition = drawSectionHeader('Skills', yPosition);
            const skillsText = skills.join(', ');
            yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
            yPosition += (4 + lineHeightSpacing);
            yPosition -= sectionSpacing;
        }
      }
      
      // Certificate: Add 20 vertical spacing before Certificates section
      if (certificates && certificates.length > 0) {
        // Certificates
        yPosition = drawSectionHeader('Certificates', yPosition);
        certificates.forEach(cert => {
            const certNameText = '• ' + (cert.name || '');
            const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
            const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
        
            yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
            yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
        
            yPosition -= lineHeightSpacing + 4;
        });

  }
}
  const generateTemplate2 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const timesRomanItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    let page = createPage();
    let { width, height } = page.getSize();

    const drawText_center = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const sanitizedText = textToDraw
                  .replace(/，/g, ',')          // Replace full-width comma with a regular comma
                  .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
                  .trim();
      const textWidth = font.widthOfTextAtSize(sanitizedText, size);
      const xPosition = (595 - textWidth) / 2; 

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }

      page.drawText(sanitizedText, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        page = createPage();
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

      const drawSectionHeader = (text, y) => {
          y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0, 0, 0));
          return y;
      };

      

      const drawAlignedText = (leftText, rightText, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
        const leftTextWidth = leftText ? font.widthOfTextAtSize(leftText, size) : 0;
        const rightTextWidth = rightText ? font.widthOfTextAtSize(rightText, size) : 0;
      
        if (y < 50) {
          page = createPage();
          y = height - 50;
        }
      
        if (leftText) {
          page.drawText(leftText, { x: 50, y, size, font, color });
        }
        
        if (rightText) {
          page.drawText(rightText, { x: width - rightTextWidth - 50, y, size, font, color });
        }
      
        return y;
      };

      const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color) => {
        // Split the line into words by spaces
        const words = line.split(' ');
        let currentX = x;

        words.forEach((word, index) => {
            let currentFont = regularFont;
            let matchingSkill = null;
            let hasPunctuation = false;

            // Remove punctuation at the end for matching purposes but preserve it for drawing
            const trimmedWord = word.replace(/[,.!?]+$/, ''); // Remove punctuation at the end
            const punctuation = word.slice(trimmedWord.length); // Store the punctuation if present

            // Check if the word exactly matches any skill in the combinedSkills list (case-sensitive)
            combinedSkills.forEach(skill => {
            if (word === skill || (trimmedWord === skill && punctuation)) {
                matchingSkill = skill;
                if (punctuation) hasPunctuation = true;
            }
            });

            // Check if y is too low, create a new page if necessary
            if (y < 50) {
            page = createPage();
            y = height - 50;
            currentX = x; // Reset x position when starting a new page
            }

            // If there's a matching skill and punctuation, bold only the matching part
            if (matchingSkill && hasPunctuation) {
            // Draw the matching part (bold)
            page.drawText(trimmedWord, {
                x: currentX,
                y,
                size,
                font: boldFont,
                color,
            });
            currentX += boldFont.widthOfTextAtSize(trimmedWord, size);

            // Draw the punctuation (regular)
            page.drawText(punctuation, {
                x: currentX,
                y,
                size,
                font: regularFont,
                color,
            });
            currentX += regularFont.widthOfTextAtSize(punctuation, size);

            } else if (matchingSkill) {
            // Draw the entire word in bold if it matches without punctuation
            page.drawText(word, {
                x: currentX,
                y,
                size,
                font: boldFont,
                color,
            });
            currentX += boldFont.widthOfTextAtSize(word, size);

            } else {
            // Draw the entire word in regular font if no match
            page.drawText(word, {
                x: currentX,
                y,
                size,
                font: regularFont,
                color,
            });
            currentX += regularFont.widthOfTextAtSize(word, size);
            }

            // Add space after the word unless it's the last word or followed by standalone punctuation
            if (index < words.length - 1) {
                currentX += regularFont.widthOfTextAtSize(' ', size); // Add space between words, except when punctuation follows
            }
        });

        return y;
      };
      
      const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
          const bullet = '• ';
          const bulletWidth = font.widthOfTextAtSize(bullet, size);
          const indent = x + bulletWidth; // Position for the subsequent lines
          const words = text.split(' ');
          let line = '';
          let firstLine = true;

          words.forEach(word => {
            const testLine = line + word + ' ';
            const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

            if (testLineWidth < maxWidth) {
              line = testLine;
            } else {
              y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
              y -= lineHeightSpacing + 4;
              line = word + ' ';
              firstLine = false;
            }
          });

          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          return y - lineHeightSpacing - 4;
      };

      const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = width - 100) => {
        if (!text) return y;
        const sanitizedText = text
        .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
        .replace(/\s+/g, ' ')      // Remove excessive spaces
        .replace(/，/g, ',')        // Replace full-width comma with a regular comma
        .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
        .trim();
        const bullet = '• ';
        const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
        const indent = x + bulletWidth; // Position for the subsequent lines after the bullet
        const words = sanitizedText.split(' ');
        let line = '';
        let firstLine = true;
      
        words.forEach(word => {
          const isSkill = combinedSkills.includes(word); // Check if the word is a skill
          const currentFont = isSkill ? boldFont : regularFont; // Use bold font if it's a skill
      
          const testLine = line + word + ' ';
          const testLineWidth = currentFont.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);
      
          if (testLineWidth < maxWidth) {
            line = testLine;
          } else {
            // Draw the current line with bullet for the first line
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
            y -= lineHeightSpacing + 4;
            line = word + ' ';
            firstLine = false;
          }
        });
      
        // Draw the last line with bullet if it's the first line, and handle font styles
        y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
        return y - lineHeightSpacing - 4;
      };

        const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            // Return immediately if text is empty or null
            if (!text) return y;
            // Sanitize the input text by removing any carriage return or newline characters
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
          
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
          
            words.forEach(word => {
              // Check if adding the next word exceeds the maxWidth
              if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                // If it exceeds, push the current line to the array and start a new line
                lines.push(line.trim()); // Trim extra space at the end of the line
                line = word + ' ';
              }
            });
          
            // Push the last line
            lines.push(line.trim());
          
            // Draw each line
            lines.forEach((line, index) => {
              y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              y -= lineHeightSpacing + 4; // Adjust the y position for the next line
            });
          
            return y;
          };

        const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            if (!text) return y;
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
              }
              y -= lineHeightSpacing + 4;
            });
            return y;
          };

          const drawTextWithWrappingSkillsCategoryKey = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            if (!text) return y;
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 50, y, size, font, rgb(0, 0, 0));
              }
            });
            return y;
          };

        // Header
        // Construct the header information dynamically
        const parts = [user_location, phone_number, email, personal_website].filter(item => item);
        const headerInfo = parts.join(' | ');

        let yPosition = height - 40;
        yPosition = drawText_center(name || '', 50, yPosition, name_fontsize, timesRomanBoldFont);
        // Only draw the header info if it's not empty
        if (headerInfo) {
          // write and add adding section spacing
          yPosition = drawText_center(headerInfo, 50, yPosition - sectionSpacing, fontsize);
        }
       

        // Summary
        if (summary) {
          yPosition -= sectionSpacing;
          yPosition = drawSectionHeader('SUMMARY', yPosition);

          yPosition -= sectionBetweenLine;

          // Draw a line 
          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          
          yPosition = drawTextWithWrapping(summary, 50, yPosition-lineHeightSpacing-4, fontsize, timesRomanFont);
          // Add extra space after the summary
          
          yPosition += (lineHeightSpacing + 4);
          yPosition -= sectionSpacing;
        } else {
          // adding section spacing
          yPosition -= sectionSpacing;
        }



        // Education
        if (education_list && education_list.length > 0) {
          yPosition = drawSectionHeader('EDUCATION', yPosition);
          
          yPosition -= sectionBetweenLine; 

        // Draw a line after EDUCATION
          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 4; 
        

          education_list.forEach((edu, index) => {
            const universityText = `${edu.university}`;
            yPosition = drawText(universityText, 50, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont); // University and location left-aligned, bold, and on its own line
            yPosition -= 4;
            const durationText = `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year} - ${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}`;
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            yPosition = drawAlignedText(degree_major_text, durationText, yPosition - lineHeightSpacing, fontsize, timesRomanFont);
            
            if (edu.GPA) {
              yPosition -= 4;
              const gpaText = `GPA: ${edu.GPA}`;
              yPosition = drawText(gpaText, 50, yPosition - lineHeightSpacing, fontsize, timesRomanFont); // Adjust x position as needed
            }
          
            // Check if the current element is the last one, adding section spacing
            if (index === education_list.length - 1) {
              yPosition -= sectionSpacing;
            } else {
              yPosition += lineHeightSpacing;
              yPosition -= sectionSpacing;
            }
          });
         
        }

        // Work Experience
        if (revisedWorkExp && revisedWorkExp.length > 0) {
          yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);

          yPosition -= sectionBetweenLine;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 4; 
        
          revisedWorkExp.forEach((exp, index) => {
            yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont);
            // yPosition = drawAlignedText(exp.Experience.Title, exp.Experience.Duration, yPosition - 10, 12, timesRomanFont);
            yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - lineHeightSpacing - 4, fontsize, timesRomanBoldItalicFont);
            yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);
        
            yPosition -= (lineHeightSpacing + 4);
           
            exp.Experience.Responsibilities.forEach(resp => {
              if (showBoldKeywords) {
                yPosition = drawBulletedText(resp, 50, yPosition);
              } else {
                yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
              }
            });
            // Check if the current element is the last one, adding section spacing
            if (index === revisedWorkExp.length - 1) {
                yPosition += (lineHeightSpacing + 4);
                yPosition -= sectionSpacing;
            } else {
                yPosition += (lineHeightSpacing + 4 + lineHeightSpacing);
                yPosition -= sectionSpacing;
            }
          });
        }
      
        // Project Experience
        if (revisedProjExp && revisedProjExp.length > 0) {
          yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);
          yPosition -= sectionBetweenLine;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= 4; 
          revisedProjExp.forEach((exp, index) => {
          
            if (exp.Experience) { // Ensure 'Experience' object exists

              yPosition = drawAlignedText(exp.Experience.Company, exp.Experience.Location, yPosition - lineHeightSpacing, fontsize, timesRomanBoldFont);
        
              yPosition = drawAlignedText(exp.Experience.Title, "", yPosition - lineHeightSpacing - 4, fontsize, timesRomanBoldItalicFont);
              yPosition = drawAlignedText("", exp.Experience.Duration, yPosition, fontsize, timesRomanItalicFont);

              yPosition -= (lineHeightSpacing + 4);

              exp.Experience.Responsibilities.forEach(resp => {
                if (showBoldKeywords) {
                    yPosition = drawBulletedText(resp, 50, yPosition);
                } else {
                    yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
                }
              });
              // Check if the current element is the last one, adding section spacing
              if (index === revisedProjExp.length - 1) {
                yPosition += (lineHeightSpacing + 4);
                yPosition -= sectionSpacing;
              } else {
                yPosition += (lineHeightSpacing + 4 + lineHeightSpacing);
                yPosition -= sectionSpacing;
              }
            }
          });
        }
        // Skills
        if (skills && skills.length > 0) {
          yPosition = drawSectionHeader('SKILLS', yPosition);
          yPosition -= sectionBetweenLine;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= (4 + lineHeightSpacing);
          if (isSkillsCategorized) {
            let lastValidIndex = -1;
            // First, identify the last valid index
            Object.keys(categorizedSkills).forEach((key, index) => {
                if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                    lastValidIndex = index;
                }
            });

            Object.keys(categorizedSkills).forEach((key, index) => {
                // Check if the value is an empty list or null
                if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                    return; // Skip to the next iteration
                }

                let skillsText = categorizedSkills[key].join(', ');
                keyText = key + ': ';
                yPosition = drawTextWithWrappingSkillsCategoryKey(keyText, 50, yPosition, fontsize, timesRomanBoldFont);
                yPosition = drawTextWithWrappingSkillsCategory(skillsText, 50 + timesRomanBoldFont.widthOfTextAtSize(keyText, fontsize), yPosition, fontsize, timesRomanFont);

                // Check if the current key is the last valid element in categorizedSkills, add sectionspacing
                if (index === lastValidIndex) {
                    yPosition += (lineHeightSpacing + 4);
                    yPosition -= sectionSpacing;
                }
            });
            } else {
                const skillsText = skills.join(', ');
                yPosition = drawTextWithWrapping(skillsText, 50, yPosition, fontsize, timesRomanFont);
                // add section spacing
                yPosition += (lineHeightSpacing + 4);
                yPosition -= sectionSpacing;
            }
        }

        // Certificates
        if (certificates && certificates.length > 0) {
          yPosition = drawSectionHeader('CERTIFICATES', yPosition);
          yPosition -= sectionBetweenLine;

          page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: width - 50, y: yPosition },
            thickness: 1,
            color: rgb(0, 0, 0),
          });
          yPosition -= (4 + lineHeightSpacing);

          certificates.forEach(cert => {
            const certNameText = '• ' + (cert.name || '');
            const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
            const certDateTextWidth = timesRomanFont.widthOfTextAtSize(certDateText, fontsize);
            yPosition = drawText(certNameText, 50, yPosition, fontsize, timesRomanFont);
            yPosition = drawText(certDateText, width - certDateTextWidth - 50, yPosition, fontsize);
            yPosition -= lineHeightSpacing + 4;
          });
        }
  };

  const generateTemplate3 = async (pdfDoc, timesRomanFont, timesRomanBoldFont) => {
    const timesRomanItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const createPage = () => {
      const page = pdfDoc.addPage([595, 842]); // A4 size page dimensions
      return page;
    };

    const getPage = (pageIndex) => {
        if (pageIndex < pdfDoc.getPageCount()) {
            return pdfDoc.getPage(pageIndex); // Access the existing page
        } else {
            return createPage(); // Create a new page if it doesn't exist
        }
    };

    let currentPageIndex = 0;
    let page = getPage(currentPageIndex); // Start with the first page
    let { width, height } = page.getSize();

    const drawText_left = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';
      const textWidth = font.widthOfTextAtSize(textToDraw, size);
      const xPosition = 50;

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }

      page.drawText(text, { x: xPosition, y, size, font, color });
      return y;
    };

    const drawText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
        // Ensure text is not null or undefined
        const textToDraw = text || '';
        const sanitizedText = textToDraw
                .replace(/，/g, ',')          // Replace full-width comma with a regular comma
                .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
                .trim();
        const textWidth = font.widthOfTextAtSize(sanitizedText, size);
        const xPosition = 397;
  
        if (y < 50) {
          // page = createPage();
          currentPageIndex += 1;
          page = getPage(currentPageIndex);
          y = height - 50;
        }
  
        page.drawText(sanitizedText, { x: xPosition, y, size, font, color });
        return y;
      };

    const drawText = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0)) => {
      // Ensure text is not null or undefined
      const textToDraw = text || '';

      if (y < 50) {
        // page = createPage();
        currentPageIndex += 1;
        page = getPage(currentPageIndex);
        y = height - 50;
      }
      page.drawText(textToDraw, { x, y, size, font, color });
      return y;
    };

      const drawSectionHeader = (text, y) => {
          y = drawText(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
          return y - 20;
      };

      const drawSectionHeader_right = (text, y) => {
        y = drawText_right(text, 50, y, fontsize, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));
        return y - 20;
      };
      

        const drawBulletedTextNoBold = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = 330) => {
          const bullet = '• ';
          const bulletWidth = font.widthOfTextAtSize(bullet, size);
          const indent = x + bulletWidth; // Position for the subsequent lines
          const words = text.split(' ');
          let line = '';
          let firstLine = true;

          words.forEach(word => {
            const testLine = line + word + ' ';
            const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);

            if (testLineWidth < maxWidth) {
              line = testLine;
            } else {
              y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
              y -= size + 4;
              line = word + ' ';
              firstLine = false;
            }
          });

          y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
          return y - size - 4;
        };


          const drawTextWithStyles = (line, x, y, size, regularFont, boldFont, color) => {
            // Split the line into words by spaces
            const words = line.split(' ');
            let currentX = x;
    
            words.forEach((word, index) => {
                let currentFont = regularFont;
                let matchingSkill = null;
                let hasPunctuation = false;
    
                // Remove punctuation at the end for matching purposes but preserve it for drawing
                const trimmedWord = word.replace(/[,.!?]+$/, ''); // Remove punctuation at the end
                const punctuation = word.slice(trimmedWord.length); // Store the punctuation if present
    
                // Check if the word exactly matches any skill in the combinedSkills list (case-sensitive)
                combinedSkills.forEach(skill => {
                if (word === skill || (trimmedWord === skill && punctuation)) {
                    matchingSkill = skill;
                    if (punctuation) hasPunctuation = true;
                }
                });
    
                // Check if y is too low, create a new page if necessary
                if (y < 50) {
                page = createPage();
                y = height - 50;
                currentX = x; // Reset x position when starting a new page
                }
    
                // If there's a matching skill and punctuation, bold only the matching part
                if (matchingSkill && hasPunctuation) {
                // Draw the matching part (bold)
                page.drawText(trimmedWord, {
                    x: currentX,
                    y,
                    size,
                    font: boldFont,
                    color,
                });
                currentX += boldFont.widthOfTextAtSize(trimmedWord, size);
    
                // Draw the punctuation (regular)
                page.drawText(punctuation, {
                    x: currentX,
                    y,
                    size,
                    font: regularFont,
                    color,
                });
                currentX += regularFont.widthOfTextAtSize(punctuation, size);
    
                } else if (matchingSkill) {
                // Draw the entire word in bold if it matches without punctuation
                page.drawText(word, {
                    x: currentX,
                    y,
                    size,
                    font: boldFont,
                    color,
                });
                currentX += boldFont.widthOfTextAtSize(word, size);
    
                } else {
                // Draw the entire word in regular font if no match
                page.drawText(word, {
                    x: currentX,
                    y,
                    size,
                    font: regularFont,
                    color,
                });
                currentX += regularFont.widthOfTextAtSize(word, size);
                }
    
                // Add space after the word unless it's the last word or followed by standalone punctuation
                if (index < words.length - 1) {
                    currentX += regularFont.widthOfTextAtSize(' ', size); // Add space between words, except when punctuation follows
                }
            });
    
            return y;
          };
          
    
          const drawBulletedText = (text, x, y, size = fontsize, regularFont = timesRomanFont, boldFont = timesRomanBoldFont, color = rgb(0, 0, 0), maxWidth = 330) => {
            if (!text) return y;
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
            const bullet = '• ';
            const bulletWidth = regularFont.widthOfTextAtSize(bullet, size);
            const indent = x + bulletWidth; // Position for the subsequent lines after the bullet
            const words = sanitizedText.split(' ');
            let line = '';
            let firstLine = true;
          
            words.forEach(word => {
              const isSkill = combinedSkills.includes(word); // Check if the word is a skill
              const currentFont = isSkill ? boldFont : regularFont; // Use bold font if it's a skill
          
              const testLine = line + word + ' ';
              const testLineWidth = currentFont.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);
          
              if (testLineWidth < maxWidth) {
                line = testLine;
              } else {
                // Draw the current line with bullet for the first line
                y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
                y -= size + 4;
                line = word + ' ';
                firstLine = false;
              }
            });
          
            // Draw the last line with bullet if it's the first line, and handle font styles
            y = drawTextWithStyles((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, regularFont, boldFont, color);
            return y - size - 4;
        };

        const drawBulletedText_right = (text, x, y, size = fontsize, font = timesRomanFont, color = rgb(0, 0, 0), maxWidth = width - 50) => {
            const bullet = '• ';
            const bulletWidth = font.widthOfTextAtSize(bullet, size);
            const indent = x + bulletWidth; // Position for the subsequent lines
            const words = text.split(' ');
            let line = '';
            let firstLine = true;
  
            words.forEach(word => {
              const testLine = line + word + ' ';
              const testLineWidth = font.widthOfTextAtSize((firstLine ? bullet : '') + testLine, size);
  
              if (testLineWidth < maxWidth - x) {
                line = testLine;
              } else {
                y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
                y -= size + 4;
                line = word + ' ';
                firstLine = false;
              }
            });
  
            y = drawText((firstLine ? bullet : '') + line, firstLine ? x : indent, y, size, font, color);
            return y - size - 4;
          };

          const drawTextWithWrapping = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = 330) => {
            // Return immediately if text is empty or null
            if (!text) return y;
            // Sanitize the input by removing newline and carriage return characters and collapsing multiple spaces
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
          
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
          
            words.forEach(word => {
              // Check if adding the word exceeds the maxWidth
              if (font.widthOfTextAtSize(line + word, size) < maxWidth) {
                line += word + ' ';
              } else {
                // If it exceeds, push the current line to the array and start a new line
                lines.push(line.trim());  // Trim any extra space at the end of the line
                line = word + ' ';
              }
            });
          
            // Push the last line
            lines.push(line.trim());
          
            // Draw each line
            lines.forEach((line, index) => {
              y = drawText(line, x, y, size, font, rgb(0, 0, 0));  // Draw the line
              y -= size + 4;  // Adjust y position for the next line
            });
          
            return y;
          };

        
          const drawTextWithWrapping_right = (text, x = 397, y, size = fontsize, font = timesRomanFont, maxWidth = width - 70) => {
            // Return immediately if text is empty or null
            if (!text) return y;
          
            // Sanitize the input text by removing carriage return, newline characters, and collapsing extra spaces
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
          
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
          
            words.forEach(word => {
              // Check if the current line plus the new word exceeds maxWidth
              if (font.widthOfTextAtSize(line + word, size) < maxWidth - x) {
                line += word + ' ';
              } else {
                // If it exceeds, push the current line to the array and start a new line
                lines.push(line.trim()); // Trim any extra space at the end of the line
                line = word + ' ';
              }
            });
          
            // Push the last line if it's not empty
            if (line) lines.push(line.trim());
          
            // Draw each line at the specified x and y position
            lines.forEach((line, index) => {
              y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              y -= size + 4; // Adjust y position for the next line
            });
          
            return y;
          };

          const drawTextWithWrappingSkillsCategory = (text, x, y, size = fontsize, font = timesRomanFont, maxWidth = width - 100) => {
            if (!text) return y;
            // const sanitizedText = text.replace(/[\r\n]+/g, ' ').replace(/\s+/g, ' ').trim();
            const sanitizedText = text
            .replace(/[\r\n]+/g, ' ')  // Sanitize newlines
            .replace(/\s+/g, ' ')      // Remove excessive spaces
            .replace(/，/g, ',')        // Replace full-width comma with a regular comma
            .replace(/‐|–|—/g, '-')       // Replace en-dash and em-dash with a regular hyphen
            .trim();
            const words = sanitizedText.split(' ');
            let line = '';
            let lines = [];
            words.forEach(word => {
              if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) >= maxWidth) {
                lines.push(line);
                line = word + ' ';
              } else if (lines.length === 0 && (x + font.widthOfTextAtSize(line + word, size) - 50) < maxWidth) {
                line += word + ' ';
              } else if (x + font.widthOfTextAtSize(line + word, size) - 50 < maxWidth) {
                line += word + ' ';
              } else {
                lines.push(line);
                line = word + ' ';
              }
            });
            lines.push(line);
            lines.forEach((line, index) => {
              if (index === 0) {
                y = drawText(line, x, y, size, font, rgb(0, 0, 0));
              } else {
                y = drawText(line, 397, y, size, font, rgb(0, 0, 0));
              }
              y -= size + 4;
            });
            return y;
          };

        // Header
        // Construct the header information dynamically
        const parts = [personal_website, email, phone_number].filter(item => item);
        //for left column
        let yPosition = height - 40;
        //for right column
        let yPosition_right_column = height - 40;
        // Only draw the header info if it's not empty
        if (parts) {
            parts.map((element, index) => {
                yPosition_right_column = drawText_right(element, 50, yPosition_right_column, fontsize);
                yPosition_right_column = yPosition_right_column - 15;
            });
        }
        if (yPosition_right_column > 757) {
            yPosition_right_column = 757;
        }
        yPosition_right_column -= 10;
       

        // Education
        if (education_list && education_list.length > 0) {
          yPosition_right_column = drawSectionHeader_right('EDUCATION', yPosition_right_column);
          
          education_list.forEach((edu, index) => {
            const universityText = `${edu.university}`;
            yPosition_right_column = drawTextWithWrapping_right(universityText, 397, yPosition_right_column, fontsize, timesRomanBoldFont); // University and location left-aligned, bold, and on its own line
            yPosition_right_column -= 1;
            let degree_major_text = "";
            if (!edu.majors || edu.majors.trim() === "") {
                degree_major_text = edu.degrees;
            } else {
                degree_major_text = `${edu.degrees} in ${edu.majors}`;
            }
            const durationText = `${monthMap[parseInt(edu.university_start_month, 10)]} ${edu.university_start_year} - ${monthMap[parseInt(edu.graduation_month, 10)]} ${edu.graduation_year}`;
            yPosition_right_column = drawTextWithWrapping_right(degree_major_text, 397, yPosition_right_column, fontsize, timesRomanFont);
            yPosition_right_column -= 1;
            if (edu.GPA) {
                const gpaText = `GPA: ${edu.GPA}`;
                yPosition_right_column = drawTextWithWrapping_right(gpaText, 397, yPosition_right_column, fontsize, timesRomanFont); // Adjust x position as needed
                yPosition_right_column -= 1;
            }
            yPosition_right_column = drawText_right(durationText, 50, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42));
          
            // Check if the current element is the last one
            if (index === education_list.length - 1) {
                yPosition_right_column -= 20;
            } else {
                yPosition_right_column -= 20;
            }
          });
        }

        if (skills && skills.length > 0) {
            yPosition_right_column -= 25;
            yPosition_right_column = drawSectionHeader_right('SKILLS', yPosition_right_column);
            if (isSkillsCategorized) {
              let lastValidIndex = -1;
              // First, identify the last valid index
              Object.keys(categorizedSkills).forEach((key, index) => {
                  if (categorizedSkills[key] && categorizedSkills[key].length > 0) {
                      lastValidIndex = index;
                  }
              });
  
              Object.keys(categorizedSkills).forEach((key, index) => {
                  // Check if the value is an empty list or null
                  if (!categorizedSkills[key] || categorizedSkills[key].length === 0) {
                      return; // Skip to the next iteration
                  }
                  let skillsText = categorizedSkills[key].join(', ');
                  yPosition_right_column = drawTextWithWrappingSkillsCategory(key, 397, yPosition_right_column, fontsize, timesRomanBoldFont);
                  yPosition_right_column -= 5;
                  yPosition_right_column = drawTextWithWrappingSkillsCategory(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
  
                  // Check if the current key is the last valid element in categorizedSkills
                  if (index === lastValidIndex) {
                    yPosition_right_column -= 5;
                  } else {
                    yPosition_right_column -= 10;
                  }
              });
              } else {
                  const skillsText = skills.join(', ');
                  yPosition_right_column = drawTextWithWrapping_right(skillsText, 397, yPosition_right_column, fontsize, timesRomanFont);
                  yPosition_right_column -= 5;
              }
          }

        // Certificates
        if (certificates && certificates.length > 0) {
            yPosition_right_column -= 25;
            yPosition_right_column = drawSectionHeader_right('CERTIFICATES', yPosition_right_column);
            certificates.forEach(cert => {
              const certNameText = (cert.name || '');
              const certDateText = cert.achieved_date ? String(cert.achieved_date) : '';
              yPosition_right_column = drawBulletedText_right(certNameText, 397, yPosition_right_column);
              yPosition_right_column = drawText_right(certDateText, 410, yPosition_right_column, 10, timesRomanFont, rgb(0.431, 0.416, 0.42))
              yPosition_right_column -= 20;
            });
        }

        currentPageIndex = 0; // Start again with the first page
        page = getPage(currentPageIndex); // Access the first page

        // Header
        yPosition -= 15;
        yPosition = drawText_left(name || '', 50, yPosition, name_fontsize_template_3, timesRomanBoldFont, rgb(0.11, 0.533, 0.929));

        // Summary
        if (summary) {
            yPosition -= 40;
            yPosition = drawSectionHeader('SUMMARY', yPosition);
            yPosition = drawTextWithWrapping(summary, 50, yPosition, fontsize, timesRomanFont);
            yPosition -= 20;
            } else {
            yPosition -= 40;
        }

        // Work Experience
        if (revisedWorkExp && revisedWorkExp.length > 0) {
          yPosition = drawSectionHeader('WORK EXPERIENCE', yPosition);
        
          revisedWorkExp.forEach((exp, index) => {
            const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
            yPosition = drawText_left(exp_title, 50, yPosition, fontsize, timesRomanBoldFont);
            yPosition -= 12;
            let location_text = '';
              if (exp.Experience.Location) {
                location_text = `${exp.Experience.Location} | ${exp.Experience.Duration}`;
              } else {
                location_text = `${exp.Experience.Duration}`
              }
            yPosition = drawText_left(location_text, 50, yPosition, fontsize, timesRomanBoldFont);
        
            yPosition -= 15;
           
            exp.Experience.Responsibilities.forEach(resp => {
              if (showBoldKeywords) {
                yPosition = drawBulletedText(resp, 50, yPosition);
              } else {
                yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
              }
            });
            // Check if the current element is the last one
            if (index === revisedWorkExp.length - 1) {
                yPosition -= 5;
            } else {
                yPosition -= 5;
            }
          });
        }
      
        // Project Experience
        if (revisedProjExp && revisedProjExp.length > 0) {
          yPosition -= 15;
          yPosition = drawSectionHeader('PROJECT EXPERIENCE', yPosition);

          revisedProjExp.forEach((exp, index) => {
            if (exp.Experience) { // Ensure 'Experience' object exists
              const exp_title = `${exp.Experience.Title} at ${exp.Experience.Company}`;
              yPosition = drawText_left(exp_title, 50, yPosition, fontsize, timesRomanBoldFont);
              yPosition -= 12;
              let location_text = '';
              if (exp.Experience.Location) {
                location_text = `${exp.Experience.Location} | ${exp.Experience.Duration}`;
              } else {
                location_text = `${exp.Experience.Duration}`
              }
              yPosition = drawText_left(location_text, 50, yPosition, fontsize, timesRomanBoldFont);

              yPosition -= 15;

              exp.Experience.Responsibilities.forEach(resp => {
                if (showBoldKeywords) {
                    yPosition = drawBulletedText(resp, 50, yPosition);
                } else {
                    yPosition = drawBulletedTextNoBold(resp, 50, yPosition);
                }
              });
              // Check if the current element is the last one
              if (index === revisedProjExp.length - 1) {
                yPosition -= 5;
              } else {
                yPosition -= 5;
              }
            }
          });
        }
  };

  const pdfUrl = useMemo(() => (pdfBlob ? URL.createObjectURL(pdfBlob) : null), [pdfBlob]);

  const generatePdf = async () => {
    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesRomanBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

    if (selectedTemplate === 0) {
      await generateTemplate1(pdfDoc, timesRomanFont, timesRomanBoldFont);
    } else if (selectedTemplate === 1) {
      await generateTemplate2(pdfDoc, timesRomanFont, timesRomanBoldFont);
    } else if (selectedTemplate === 2) {
      await generateTemplate3(pdfDoc, timesRomanFont, timesRomanBoldFont);
    }

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    setPdfBlob(pdfBlob);
  };

  const openModal2 = () => {
      setIsModal2Open(true);
      document.body.style.overflow = "hidden";
  };

  const closeModal2 = () => {
    setIsModal2Open(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    if (companyName.trim() !== '' && jobPosition.trim() !== '' ) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
}, [companyName, jobPosition]);

  useEffect(() => {
    return () => {
        document.body.style.overflow = ""; // Restore scrolling
      };
  }, [isModal2Open]);

  useEffect(() => {
    console.log('Selected template changed:', selectedTemplate); 
    generatePdf();
  }, [selectedTemplate, showBoldKeywords, fontsize, name_fontsize, template_1_section_header, 
    name_fontsize_template_3, sectionSpacing, sectionBetweenLine, lineHeightSpacing]);

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    let jobPositionPart = jobPosition ? `_${jobPosition}` : '';
    let companyNamePart = companyName ? `_${companyName}` : '';

    link.download = `${data.personal_info['First Name']}_${data.personal_info['Last Name']}${jobPositionPart}${companyNamePart}_Resume.pdf`;
    link.click();
  };

  const handleTemplateClick = (index) => {
    setSelectedTemplate(index);
  };

  const handleBackClick = () => {
    //resetExpCopy is passing a reference to it
    navigate('/ResumeResults', { state: { data: location.state?.data, 
                                          job_description: location.state?.job_description, 
                                          company_analysis: location.state?.company_analysis, 
                                          original_data: location.state?.original_data, 
                                          work_exp_length: location.state?.work_exp_length, 
                                          mode: location.state?.mode, 
                                          executive_summary: executive_summary, 
                                          selectedExperienceIndex: 0,
                                          resetExpCopy: location.state?.resetExpCopy,
                                          jd_keywords: location.state?.jd_keywords,
                                          isSkillsCategorized: location.state?.isSkillsCategorized,
                                          categorizedSkills: location.state?.categorizedSkills,
                                          currentSection: 'experiences',
                                          jobPosition: jobPosition,
                                          companyName: companyName,
                                          companyDomain: companyDomain,
                                          jobDescriptionText: location.state?.jobDescriptionText,
                                          coverLetter: location.state?.coverLetter,
                                          application_id: application_id,
                                          fontsize: fontsize,
                                          name_fontsize: name_fontsize,
                                          sectionSpacing: sectionSpacing,
                                          sectionBetweenLine: sectionBetweenLine,
                                          lineHeightSpacing: lineHeightSpacing,
                                          template_1_section_header: template_1_section_header,
                                          name_fontsize_template_3: name_fontsize_template_3
                                         } });
  }

  // new function for the resume object & coverletter saving
  const sendToServer_db = async (navigateTo) => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return;
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    setLoading(true);
    // create a FormData object
    const formData = new FormData();
    const namingInfo = {
        jobPosition: jobPosition,
        companyName: companyName
    };
    const store_data = {
        originalData: original_data,
        expData: exp_data,
        namingInfo: namingInfo,
        executiveSummary: executive_summary === null ? null : executive_summary,
        templateIndex: selectedTemplate,
        isShowBoldKeywords: showBoldKeywords,
        boldKeywordsList: combinedSkills,
        fontsize: fontsize,
        name_fontsize: name_fontsize,
        sectionSpacing: sectionSpacing,
        sectionBetweenLine: sectionBetweenLine,
        lineHeightSpacing: lineHeightSpacing,
        template_1_section_header: template_1_section_header,
        name_fontsize_template_3: name_fontsize_template_3
    };
    const coverLetterData = {
        coverletter: location.state?.coverLetter,
        name: `${original_data.personal_info.first_name} ${original_data.personal_info.last_name}`,
        email: original_data.personal_info.email,
        phone: original_data.personal_info.phone_number,
        personal_web: original_data.personal_info.personal_website_url,
        company: companyName,
        jobPosition: jobPosition
    };
    store_data.originalData.isSkillsCategorized = isSkillsCategorized;
    store_data.originalData.categorizedSkills = categorizedSkills;
    // append the file and the job description
    formData.append('resume_json', JSON.stringify(store_data));
    formData.append('company', companyName);
    formData.append('job_position', jobPosition);
    formData.append('domain', companyDomain);
    if (location.state?.coverLetter) {
        formData.append('coverletter_json', JSON.stringify(coverLetterData));
    }

    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    if (application_id) {
        formData.append('application_id', application_id)
        fetch('/resumasterapi/update_applications_resume', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            setLoading(false);
            navigate(navigateTo);
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoading(false);

            alert(error.message);  // Displaying the error message to the user
        });
    } else {
        formData.append('job_description', location.state?.job_description);
        formData.append('application_status', "Applied");
        fetch('/resumasterapi/add_applications', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            //---------------------------------auhentication code----------------------------------------
            setLoading(false);
            navigate(navigateTo);
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoading(false);

            alert(error.message);  // Displaying the error message to the user
        });
        }
    }

    // Open modal and store pending navigation (destination)
    const handleNavigationAttempt = (destination) => {
        setPendingNavigation(destination);
        setIsModal1Open(true);
        document.body.style.overflow = "hidden"; // Disable scrolling when modal is open
    };

    const confirmNavigation = () => {
        console.log("comfirmNavigation")
        setIsModal1Open(false);
        if (pendingNavigation) {
            navigate(pendingNavigation); // Navigate to the stored destination
        }
        document.body.style.overflow = ""; // Re-enable scrolling
    };

    const openModal1 = () => {
        setIsModal1Open(true);
        document.body.style.overflow = "hidden";
    };
  
    const closeModal1 = () => {
          setIsModal1Open(false);
          document.body.style.overflow = "";
    };

    useEffect(() => {
        return () => {
            document.body.style.overflow = ""; // Restore scrolling
          };
    }, [isModal1Open]);

return (
    isAuthenticated ? (
      <div className="pdf-generator">
        <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-F812QPTDD9');
          `}
        </script>
          <title>Preview - ResuMaster</title>
          <meta property="og:title" content="Preview - ResuMaster" />
          <script src="//embed.typeform.com/next/embed.js"></script>
        </Helmet>
        {isAuthenticated ? (
            <NavigationLoggedinEditor 
                className="navigation-2202" 
                onLinkClick={handleNavigationAttempt} // Pass function to handle navigation click
            />
        ) : (
            <Navigation className="navigation-2202" />
        )}
        <ModalCloseEditor 
            isOpen={isModal1Open} 
            handleYesModal={confirmNavigation} // Confirm navigation
            closeModal={closeModal1} // Cancel navigation
        />
        <div className={`dimmer-delete ${isModal1Open ? 'active' : ''}`}></div>

        <div className="pdf-generator-main-content">
          <div className="preview-section">
            <div className='pdf-preview-back-to-edit'>
                <div className="back-to-edit" onClick={handleBackClick}>
                <img className="preview-back-button-img" src='/img/chevron-left.png' alt="Back Button" />
                <div className="preview-back-button-text">Back to edit</div>
                </div>
                {selectedTemplate === 2 ? (
                    <label className="bold-keywords-checkbox">
                        <input
                        type="checkbox"
                        checked={showBoldKeywords}
                        onChange={() => setShowBoldKeywords(!showBoldKeywords)}
                        />
                        Bold Keywords
                    </label>
                    ) : (
                        <div>
                            <TemplateToolBar
                            fontsize={fontsize}
                            decreaseFontSize={decreaseFontSize}
                            increaseFontSize={increaseFontSize}
                            sectionSpacing={sectionSpacing}
                            setSectionSpacing={setSectionSpacing}
                            setSectionBetweenLine={setSectionBetweenLine}
                            lineHeightSpacing={lineHeightSpacing}
                            setLineHeightSpacing={setLineHeightSpacing}
                            showBoldKeywords={showBoldKeywords}
                            setShowBoldKeywords={setShowBoldKeywords}
                            ZoomInButton={ZoomInButton} // Pass as prop
                            ZoomOutButton={ZoomOutButton} // Pass as prop
                            Zoom={Zoom} // Pass as prop
                            />
                        </div>
                    )}
            </div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {pdfUrl ? (
                <div style={{ minHeight: '750px', marginBottom: '20px', paddingTop: '20px', paddingBottom: '20px', backgroundColor: '#fff'}}>
                    {selectedTemplate === 2 && (
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <ZoomOutButton /> {/* Button to zoom out */}
                            <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.0, 2.4]} />
                            <ZoomInButton />  {/* Button to zoom in */}
                        </div>
                    )}
                    <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance]} />
                </div>
                ) : (
                    <p>Loading PDF...</p>
                )}
            </Worker>
            
              <div
                data-tf-widget="MUjQuSF6"
                data-tf-opacity="100"
                data-tf-iframe-props="title=Resumaster.AI feedbacks"
                data-tf-transitive-search-params
                data-tf-medium="snippet"
                style={{ width: '100%', height: '400px', borderRadius: 10, marginTop:'50px'}}
              />
            
          </div>
          <div className="templates-section">
            <h2 className="templates-header">Templates</h2>
            <div className="template-list">
                {[1,0,2].map((element, index) => (
                    <div
                    key={element}
                    className={`template-thumbnail ${selectedTemplate === element ? 'selected' : ''}`}
                    onClick={() => handleTemplateClick(element)}
                    >
                    <img src={`/img/template_${element + 1}.png`} alt={`Template ${element + 1}`} />
                    <p>{element === 0 ? 'Modern' : element === 1 ? 'Classic' : 'Innovative'}</p>
                    </div>
                ))}
            </div>
            <div className="pdf-generator-button-container">
              <button onClick={openModal2} className="download-button-save">
                Save to Application
              </button>
              <div className={`dimmer-delete ${isModal2Open ? 'active' : ''}`}></div>
              {isModal2Open && ( 
                <div className="save-application-resume-modal-profile">
                    <div className="save-application-modal-content">
                        <div style={{ marginBottom: '30px', fontSize: '20px',fontWeight: '700' }}>Please fill application details before saving</div>
                        <div className="save-application-modal-group">
                            <label className="add-application-label" htmlFor="position">
                                Target Company <span style={{ color: '#B00000' }}>*</span>
                            </label>
                            <CompanyInputBar
                                initialCompanyName={companyName} // Pass initial company name
                                initialCompanyDomain={companyDomain} // Pass initial company domain (logo URL)
                                onCompanyChange={handleCompanyChange} // Update company name state
                                onDomainChange={handleDomainChange} // Update company domain (logo) state
                            />
                        </div>
                        <div className="save-application-modal-group">
                            <label className="add-application-label" htmlFor="position">
                                Target Position <span style={{ color: '#B00000' }}>*</span>
                            </label>
                            <input
                                value={jobPosition} 
                                onChange={e => setJobPosition(e.target.value)}
                                className={`add-application-input ${jobPosition.trim() === '' ? 'input-error' : ''}`}
                            />
                        </div>
                        <div className="save-application-modal-button-container">
                            <button className="save-resume-application-button-cancel" onClick={closeModal2}>
                                Cancel
                            </button>
                            <button className="save-resume-application-button-save" onClick={() => sendToServer_db('/Dashboard')} disabled={isButtonDisabled}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
              <button onClick={downloadPdf} className="download-button-export">
                Export PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>Please log in first</div>
    )
  );    
  
};