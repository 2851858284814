import React from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import "./style.css";

export const Loading_mainpage = () => {
    //---------------------------------auhentication code----------------------------------------
    const { isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
  return (
    <div className="loading">
      <Helmet>
        <title>Loading - ResuMaster</title>
        <meta property="og:title" content="Loading - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="frame-10">
        <div className="frame-11">
          <img
            className="loading-logo"
            alt="loading-logo"
            src="/img/nav-logo-new.png"
            style={{width:'300px'}}
          />
          <p className="title-5">Please waiting</p>
        </div>
        <div className="spinner-container">
          <div className="loading-spinner">
          </div>
        </div>
      </div>
    </div>
  );
};
