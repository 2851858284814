import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import './AddApplication.css';

const AddApplication = ({ isOpen, onClose, onSubmit }) => {
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const popupRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const typingTimeoutRef = useRef(null); // To store the timeout ID
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const companyLogoDropdown = useRef(null);
    const quillRef = useRef(null);

	const [tempDate, setTempDate] = useState(new Date());

	const formatDateToYMD = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [tempFormData, setTempFormData] = useState({
        company_name: '',
		company_domain: '',
        position: '',
        job_description: '',
        status: 'applied',
        applied_date: formatDateToYMD(new Date()),
        screen_date: null,
        interview_date: null,
        offer_date: null,
        rejected_date: null,
    });

	const handleDateChange = (e) => {
		const value = e.target.value;
        const currentDate = new Date(tempDate);
        const [year, month, day] = value.split('-').map(Number);
        currentDate.setFullYear(year);
        currentDate.setMonth(month - 1); // JS中的月份是从0开始的，所以要减1
        currentDate.setDate(day);
        const now = new Date();
        currentDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
        setTempDate(currentDate);
    };

    const handleExtractPlainText = (editorRef) => {
        if (editorRef.current) {
			const editor = editorRef.current.getEditor(); // Get the Quill instance from ReactQuill
			const plainText = editor.getText(); // Get the plain text from Quill
			console.log(plainText); // Use or send the plain text to your GPT AI
			return plainText;
        }
    };

    const isEditorEmpty = (editorRef) => {
        if (editorRef.current) {
			const editor = editorRef.current.getEditor(); // Get the Quill instance
			const text = editor.getText().trim(); // Get the plain text and trim whitespace
			console.log(text);
			return text.length === 0; // Returns true if there is no significant text
        }
        return true; // Default to true if no editor instance found
    };

	
	const formatDateTimeToUTC = (date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
	
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const statusOptions = ['applied', 'screen', 'interview', 'offer', 'rejected'];

    const handleStatusChange = (status) => {
        setIsStatusOpen(false);
        
        setTempFormData(prev => {
            const updatedCompany = { ...prev, status: status };
            const currentIndex = statusOptions.indexOf(status);
            
            statusOptions.forEach((option, index) => {
                const dateKey = `${option.toLowerCase()}_date`;
                if (index === currentIndex) {
                    updatedCompany[dateKey] = tempFormData.date;
                } else if (index > currentIndex || 
                    (status === 'offer' && option === 'rejected') || 
                    (status === 'rejected' && option === 'offer')) {
                    updatedCompany[dateKey] = null;
                }
            });
    
            return updatedCompany;
        });
    };

	const resetTempValues = () => {
		setTempDate(new Date());
		setTempFormData({
			company_name: '',
            company_domain: '',
            position: '',
            job_description: '',
            status: 'applied',
            applied_date: formatDateToYMD(new Date()),
            screen_date: null,
            interview_date: null,
            offer_date: null,
            rejected_date: null,
		});
	};

    const handleChange = (e) => {
        const { name, value } = e.target;
		console.log(name)
		console.log(value)
        setTempFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

	const handleQuillChange = (value) => {
		setTempFormData(prevData => ({
			...prevData,
			job_description: value  // 手动更新 job_description
		}));
	};

    // const createUTCStageDates = () => {
	// 	const updatedDates = {};
	
	// 	// 遍历每个状态的日期并转换为 UTC 格式
	// 	Object.entries(tempStageDates).forEach(([key, value]) => {
	// 		if (value) {
	// 			const currentDate = new Date(tempDate);
	// 			const [year, month, day] = value.split('-').map(Number);
	// 			currentDate.setFullYear(year);
	// 			currentDate.setMonth(month - 1); // JS中的月份是从0开始的，所以要减1
	// 			currentDate.setDate(day);
	
	// 			// 将日期转换为 UTC 格式并存储到字典中
	// 			updatedDates[key] = formatDateTimeToUTC(currentDate);
	// 		} else {
	// 			updatedDates[key] = ''; // 如果值为空，保留为空
	// 		}
	// 	});
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const submitObject = {
            company_name: tempFormData.company_name,
            company_domain: tempFormData.company_domain,
            position: tempFormData.position,
            job_description: tempFormData.job_description,
            status: tempFormData.status,
            applied_date: tempFormData.applied_date ? formatDateTimeToUTC(tempFormData.applied_date) : null,
            screen_date: tempFormData.screen_date ? formatDateTimeToUTC(tempFormData.screen_date) : null,
            interview_date: tempFormData.interview_date ? formatDateTimeToUTC(tempFormData.interview_date) : null,
            offer_date: tempFormData.offer_date ? formatDateTimeToUTC(tempFormData.offer_date) : null,
            rejected_date: tempFormData.rejected_date ? formatDateTimeToUTC(tempFormData.rejected_date) : null
        };
    
        // 提交表单数据
        onSubmit(submitObject);
        resetTempValues(); // 重置临时值
        onClose();
    };

    const handleClose = (e) => {
        e.stopPropagation();
        // Reset temporary form data
        resetTempValues();
        onClose();
    };

    useEffect(() => {
        setTempFormData(prevData => {
            const updatedData = { ...prevData, date: tempDate };
            
            // 更新当前状态的日期
            const currentStatusDateKey = `${prevData.status}_date`;
            updatedData[currentStatusDateKey] = tempDate;
    
            // 清除后续状态的日期
            const currentIndex = statusOptions.indexOf(prevData.status);
            statusOptions.forEach((status, index) => {
                if (index > currentIndex) {
                    updatedData[`${status}_date`] = '';
                }
            });
    
            return updatedData;
        });
    }, [tempDate]);

    useEffect(() => {
        if (tempFormData.company_name.trim() !== '' && tempFormData.position.trim() !== '' ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [tempFormData.company_name, tempFormData.position]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                handleClose(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsStatusOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    const getSliderLabels = () => {
        const currentIndex = statusOptions.indexOf(tempFormData.status);
        return statusOptions.map((status, index) => {
            if ((status === 'offer' || status === 'rejected') && status !== tempFormData.status) {
                return null;
            }
            if (index > currentIndex) return null;
        
            return (
                <div key={status} style={{
                    position: 'absolute',
                    left: `${(statusToSliderValue[status] / 3) * 100}%`,
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    fontWeight: 600,
                    color: '#2a2a2a',
                    // fontSize: '0.95em',
                    whiteSpace: 'nowrap',
                }}>
                    <span style={{ marginBottom: '5px' }}>
                        {/* {status} */}
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </span>
                </div>
            );
        }).filter(Boolean);
    };

    const getSliderDates = () => {
        return statusOptions.map((status, index) => {
            const dateKey = `${status.toLowerCase()}_date`;
            const date = tempFormData[dateKey];
    
            return (
                <div key={status} style={{
                    position: 'absolute',
                    left: `${(statusToSliderValue[status] / 3) * 100}%`,
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                }}>
                    {date && (
                        <span style={{ fontSize: '0.9em', color: '#666' }}>
                            {formatDateToYMD(date)}
                        </span>
                    )}
                </div>
            );
        });
    };
  
    const statusToSliderValue = {
      'applied': 0,
      'screen': 1,
      'interview': 2,
      'offer': 3,
      'rejected': 3
    };

    const renderStageMarkers = () => {
        return statusOptions.map((status, index) => {
          const isSelected = statusOptions.indexOf(tempFormData.status) >= index;
          const style = {
            left: status === 'rejected' ? '100%' : `${(index / 3) * 100}%`
          };
          return (
            <div
              key={status}
              className={`stage-marker ${isSelected ? 'selected' : 'unselected'} ${status === 'rejected' ? 'rejected' : ''}`}
              style={style}
            />
          );
        });
    };

    const statusColors = {
        applied: '#6750A4',
        screen: '#D29C27',
        interview: '#497DA1',
        offer: '#53765C',
        rejected: '#AAAAAA'
    };

    const statusBackgroundColors = {
        applied: '#E9E3FB',
        screen: '#F9F3E6',
        interview: '#E3EEFB',
        offer: '#DEECDC',
        rejected: '#E5E5EA'
    }

    const statusItemStyle = (color) => ({
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: `${statusBackgroundColors[color]}`,
        borderRadius: '16px',
        padding: '4px 8px',
        marginLeft: '4px',
    });

    const dotStyle = (color) => ({
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
        marginRight: '6px'
    });



    const fetchCompanyDomains = (query) => {
        fetch(`/resumasterapi/search_relative_domains?q=${query}`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`API call failed with status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            setCompanySuggestions(data);
            setShowDropdown(true);
          })
          .catch(error => {
            console.error('Error fetching company domains:', error);
            setCompanySuggestions([]);
            setShowDropdown(false);
          });
      };
    
	const handleCompanyInputChange = (e) => {
		
		const { name, value } = e.target;
		
		setTempFormData(prevData => ({
			...prevData,
			[name]: value
		}));

		if (value === '') {
			setTempFormData(prevData => ({
				...prevData,
				company_domain: ''
			}));
		}

		// Clear previous timeout
		if (typingTimeoutRef.current) {
			clearTimeout(typingTimeoutRef.current);
		}

		// Set a new timeout to wait for the user to stop typing
		typingTimeoutRef.current = setTimeout(() => {
			if (value.trim()) {
				fetchCompanyDomains(value); // Call the API after the user stops typing
			}
		}, 600); // Wait for 0.6 second after the user stops typing
	};

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose(); // Close the modal if clicked outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component is unmounted
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsStatusOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
			if (
				companyLogoDropdown.current &&
				!companyLogoDropdown.current.contains(event.target) && 
				inputRef.current &&
				!inputRef.current.contains(event.target)
			) {
				setShowDropdown(false); // Close the dropdown if clicked outside
			}
        };
    
        // Attach the event listener when dropdown is visible
        if (showDropdown) {
          	document.addEventListener('mousedown', handleClickOutside);
        } else {
          	document.removeEventListener('mousedown', handleClickOutside);
        }
    
        // Cleanup the event listener on unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

	if (!isOpen) return null;

	return (
		<div className="add-application-modal-overlay">
			<div className="add-application-modal-content" ref={popupRef} onClick={e => e.stopPropagation()}>
				<div className="add-application-modal-scroll-content">
					<h2 className="add-application-title">Add New Application</h2>
					<form onSubmit={handleSubmit} className="add-application-form">
						<div class="add-application-form-row">
                            <div className="add-application-form-group">
                                <label className="add-application-label" htmlFor="company_name">
                                    Company <span style={{ color: '#B00000' }}>*</span>
                                </label>

                                <div style={{ position: 'relative', width: '100%' }}>
                                        {/* Conditionally render the logo if resumeCompanyDomain is set */}
                                        {tempFormData.company_domain && (
                                        <img
                                            src={tempFormData.company_domain}
                                            alt={tempFormData.company_name}
                                            style={{
                                            position: 'absolute',
                                            left: '10px', // Position inside the input
                                            top: '50%',
                                            transform: 'translateY(-50%)', // Vertically center the image
                                            height: '25px', // Adjust height to fit inside input
                                            width: '25px',  // Ensure width is proportionate
                                            zIndex: 1, // Make sure the image is on top
                                            }}
                                        />
                                        )}
                                        <input
											id="company_name"
                                    		name="company_name"
											ref={inputRef}
											value={tempFormData.company_name}
											placeholder="e.g. Google"
											onChange={handleCompanyInputChange} // Using the debounced change handler
											className={`company-container ${tempFormData.company_name.trim() === '' ? 'input-error' : ''}`}
											style={{
												paddingLeft: tempFormData.company_domain ? '40px' : '10px', // Space for the image
												width: '100%', // Ensure input takes full width of the parent div
												boxSizing: 'border-box', // Ensure padding is included in width calculation
												zIndex: 0, // Ensure input is behind the image
											}}
                                        />
                                    
                                    {showDropdown && companySuggestions.length > 0 && (
                                        <div className="popup-styled-dropdown-container" ref={companyLogoDropdown}>
                                        {companySuggestions.map((company, index) => (
                                            <div
                                            key={index}
                                            className="styled-dropdown-item"
                                            onClick={() => {
                                                // Set the company name and logo when the dropdown item is clicked
                                                // setCompanyName(company.name);
												setTempFormData(prevData => ({
													...prevData,
													company_name: company.name
												}));
                                                // setCompanyDomain(company.logo_url);
												setTempFormData(prevData => ({
													...prevData,
													company_domain: company.logo_url
												}));
                                                setShowDropdown(false); // Optionally close the dropdown after selection
                                            }}
                                            >
                                            <div className="dropdown-logo">
                                                <img src={company.logo_url} alt={company.name} />
                                            </div>
                                            <div className="dropdown-content">
                                                <span className="company-name">{company.name}</span>
                                                <span className="company-domain">{company.domain}</span>
                                            </div>
                                            </div>
                                        ))}
                                        </div>
                                )}
                                </div>
                            </div>
                            <div className="add-application-form-group">
                                <label className="add-application-label" htmlFor="position">
                                    Position title <span style={{ color: '#B00000' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    id="position"
                                    name="position"
                                    placeholder="e.g. Software Engineer"
                                    value={tempFormData.position}
                                    onChange={handleChange}
                                    className={`add-application-input ${tempFormData.position.trim() === '' ? 'input-error' : ''}`}
                                />
                            </div>
                        </div>
   
						{/* <div class="add-application-form-row">
							<div className="add-application-form-group">
								<label className="add-application-label">Resume</label>
								<div className="resume-placeholder" style={{ backgroundColor: '#FAF9FF', fontWeight: 400 }}>
									{tempFormData.resume}
								</div>
							</div>
						</div> */}

						<div class="add-application-form-row">
							<div className="add-application-form-group">
								<label className="add-application-label">Job Description</label>
								<ReactQuill
									id="job_description"
									ref={quillRef}
									value={tempFormData.job_description}
									onChange={handleQuillChange}  // 使用自定义的 handleQuillChange
									placeholder="Copy and paste the qualification section of the job description"
									modules={{
										toolbar: [
											[{ list: 'ordered' }, { list: 'bullet' }]
										]
									}}
									className="popup-custom-quill-editor"
								/>
                            </div>
						</div>
						
						<div class="add-application-form-status-row">
							<div className="add-application-form-status">
								<label className="add-application-label">Application Status</label>
								<div ref={dropdownRef} className="application-status-dropdown">
									<div className="add-application-input-temp" onClick={() => setIsStatusOpen(!isStatusOpen)} tabIndex="0">
										<div style={statusItemStyle(tempFormData.status)}>
											<div style={dotStyle(statusColors[tempFormData.status])}></div>
											<span style={{textTransform: 'capitalize'}}>{tempFormData.status}</span>
										</div>
									</div>
									{isStatusOpen && (
										<div className="application-status-dropdown-open" >
											{statusOptions.map((status) => (
												<div className="add-applications-status-dropdown-item" key={status} onClick={() => handleStatusChange(status)}>
													<div style={statusItemStyle(status)}>
														<div style={dotStyle(statusColors[status])}></div>
														<span style={{textTransform: 'capitalize'}}>{status}</span>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>

							<div className="add-application-form-timeline">
									<div className="status-timeline-title">
										{getSliderLabels()}
									</div>
									<div className="status-slider-container">
										{renderStageMarkers()}
										<input type="range" min="0" max="3" value={statusToSliderValue[tempFormData.status]} className="status-slider" 
											style={{ '--slider-value': statusToSliderValue[tempFormData.status] }} readOnly />
									</div>
									<div className="status-timeline-date">
										{getSliderDates()}
									</div>
							</div>
						</div>


						<div className="add-application-form-date">
                            <label className="add-application-label">Date</label>
                            <input
                                type="date"
                                name="date"
                                value={formatDateToYMD(tempFormData.date)}
                                onChange={handleDateChange}
                                required
                                className="add-application-input-temp"
                                onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                                onClick={(e) => e.target.showPicker()} // Show the calendar picker on click
                            />
                        </div>
							
							<div className="add-application-form-btns">
                                <div className="add-application-button-group">
                                    <button type="button" onClick={handleClose} className="global-cancel-button">Cancel</button>
                                    <button type="submit" className="global-save-button" disabled={isButtonDisabled}>Save</button>

                                </div>
                            </div>
                    </form>
                </div>
            </div>
        </div>
    ); 
};

export default AddApplication;