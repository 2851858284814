import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Navigation } from "../../components/Navigation";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./style.css";

export const ResuBot = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false); // state for loading
  const [chatHistory, setChatHistory] = useState([]); // state for chat history
  const [revisedDescriptionIndex, setRevisedDescriptionIndex] = useState([]); // state for revised description indices
  const [previousRevisedDescriptionIndex, setPreviousRevisedDescriptionIndex] = useState([]);

  const [undoText, setUndoText] = useState('Undo Revision');
  const [latestUndoIndex, setLatestUndoIndex] = useState(null); // state for latest undo button index
  const chatHistoryRef = useRef(null); // ref for chat history

  // use the dynamic real data
  const location = useLocation();
  const [upcoming_data, setUpcomingData] = useState(location.state?.data);
  const exp_index = location.state?.selectedExperience;
  const mode = location.state?.mode;
  const data = upcoming_data.rendering_data[location.state?.currentSection][exp_index];

  console.log(location.state?.jobPosition);
  console.log(location.state?.companyName);
  
  // used for testing only
  // const data = require('./data.json');

  // State for previous and current experiences
  const [currentExperience, setCurrentExperience] = useState(data.Experience);
  const [previousExperience, setPreviousExperience] = useState(data.Experience);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default newline action
      sendToServer();
    }
  };

  const handleSave = () => {
    // Create a copy of the rendering_data array
    const newRenderingData = upcoming_data.rendering_data;

    // Ensure that the index and structure exist before updating
    if (newRenderingData[location.state?.currentSection][exp_index] && newRenderingData[location.state?.currentSection][exp_index].Experience) {
        newRenderingData[location.state?.currentSection][exp_index].Experience.Responsibilities = currentExperience.Responsibilities;
    } else {
        console.error("Invalid index or structure in rendering_data");
        return;
    }

    // Create a new object with the updated rendering_data
    const new_data = {
        ...upcoming_data,
        rendering_data: newRenderingData
    };
    navigate('/ResumeResults', {
        state: {
          data: new_data,
          job_description: location.state?.job_description,
          company_analysis: location.state?.company_analysis,
          original_data: location.state?.original_data,
          mode: location.state?.mode,
          executive_summary: location.state?.executive_summary,
          selectedExperienceIndex: exp_index,
          resetExpCopy: location.state?.resetExpCopy,
          jd_keywords: location.state?.jd_keywords,
          isSkillsCategorized: location.state?.isSkillsCategorized,
          categorizedSkills: location.state?.categorizedSkills,
          currentSection: location.state?.currentSection === "work" ? "experiences" : "projects",
          jobPosition: location.state?.jobPosition,
          companyName: location.state?.companyName,
          companyDomain: location.state?.companyDomain,
          jobDescriptionText: location.state?.jobDescriptionText,
          coverLetter: location.state?.coverLetter,
          application_id: location.state?.application_id,
          fontsize: location.state?.fontsize,
          name_fontsize: location.state?.name_fontsize,
          sectionSpacing: location.state?.sectionSpacing,
          sectionBetweenLine: location.state?.sectionBetweenLine,
          lineHeightSpacing: location.state?.lineHeightSpacing,
          template_1_section_header: location.state?.template_1_section_header,
          name_fontsize_template_3: location.state?.name_fontsize_template_3
        }
      });
  };

  const handleReturn = () => {
    navigate('/ResumeResults', {
        state: {
          data: upcoming_data,
          job_description: location.state?.job_description,
          company_analysis: location.state?.company_analysis,
          original_data: location.state?.original_data,
          mode: location.state?.mode,
          executive_summary: location.state?.executive_summary,
          selectedExperienceIndex: exp_index,
          resetExpCopy: location.state?.resetExpCopy,
          jd_keywords: location.state?.jd_keywords,
          isSkillsCategorized: location.state?.isSkillsCategorized,
          categorizedSkills: location.state?.categorizedSkills,
          currentSection: location.state?.currentSection === "work" ? "experiences" : "projects",
          jobPosition: location.state?.jobPosition,
          companyName: location.state?.companyName,
          companyDomain: location.state?.companyDomain,
          jobDescriptionText: location.state?.jobDescriptionText,
          coverLetter: location.state?.coverLetter,
          application_id: location.state?.application_id,
          fontsize: location.state?.fontsize,
          name_fontsize: location.state?.name_fontsize,
          sectionSpacing: location.state?.sectionSpacing,
          sectionBetweenLine: location.state?.sectionBetweenLine,
          lineHeightSpacing: location.state?.lineHeightSpacing,
          template_1_section_header: location.state?.template_1_section_header,
          name_fontsize_template_3: location.state?.name_fontsize_template_3
        }
      });
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const sendToServer = async () => {
    if (!isAuthenticated) {
      alert("Login required: Please login first");
      return;
    }
  
    // Add user prompt to chat history immediately
    let newChatHistory = [...chatHistory, { type: 'user', message: userInput }];
    setChatHistory(newChatHistory);
    
    // Clear the input field
    setUserInput('');
  
    // Prepare the chat history to send to the backend
    const latestChatHistory = newChatHistory.slice(-6); // Get the latest three pairs (6 entries)
  
    // create a FormData object
    const formData = new FormData();
    formData.append('experience', JSON.stringify(currentExperience));
    formData.append('prompt', userInput);
    formData.append('mode', mode);
    formData.append('chat_history', JSON.stringify(latestChatHistory)); // Include the chat history
  
    // Start loading
    setLoading(true);
  
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('/resumasterapi/resubot_revision', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('API request failed');
      }
  
      const result = await response.json();
  
      if (result.revise_mode === false) {
        newChatHistory = [
          ...newChatHistory,
          { type: 'bot', message: result.response, withUndo: false }
        ];
      } else {
        // Update responsibilities based on Revised_description_index
        const updatedResponsibilities = result.Responsibilities;
  
        const updatedExperience = {
          ...currentExperience,
          Responsibilities: updatedResponsibilities,
        };
  
        const current_responsibility_length = currentExperience.Responsibilities.length;
        // Update previous experience and revised description indices
        setPreviousExperience(currentExperience);
        setPreviousRevisedDescriptionIndex(revisedDescriptionIndex);
  
        // Update current experience with the response
        setCurrentExperience(updatedExperience);
        // Update revised description indices
        console.log(current_responsibility_length);
        if (updatedResponsibilities.length >= current_responsibility_length) {
          setRevisedDescriptionIndex(result.Revised_description_index);
        } else {
          setRevisedDescriptionIndex([]);
        }
  
        // Add API response to chat history with undo option
        newChatHistory = [
          ...newChatHistory,
          { type: 'bot', message: result, withUndo: true }
        ];
  
        // Reset undo button text
        setUndoText('Undo Revision');
      }
  
      // Trim the chat history to keep only the latest 3 pairs and retain undo functionality
      const trimmedChatHistory = trimChatHistory(newChatHistory);
      setChatHistory(trimmedChatHistory);
      console.log(trimmedChatHistory);
  
      // Ensure undo functionality retains the correct index
      const latestUndoIdx = (() => {
        for (let i = trimmedChatHistory.length - 1; i >= 0; i--) {
        if (trimmedChatHistory[i].withUndo) return i;
        }
        return -1;
      })();
      setLatestUndoIndex(latestUndoIdx);
      console.log(latestUndoIdx);
      setLatestUndoIndex(latestUndoIdx);
      console.log(chatHistory);
  
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const trimChatHistory = (history) => {
    const maxPairs = 3;
    const pairCount = Math.floor(history.length / 2);
    if (pairCount > maxPairs) {
      return history.slice((pairCount - maxPairs) * 2);
    }
    return history;
  };
  
  const handleUndo = () => {
    // Revert to the previous experience and revised description indices
    setCurrentExperience(previousExperience);
    setRevisedDescriptionIndex(previousRevisedDescriptionIndex);
    // Update the undo button text
    setUndoText('Revision discarded');
  };

  return (
    isAuthenticated ? (
      <div className="resubot-page">
        <Helmet>
          <title>ResuBot - ResuMaster</title>
          <meta property="og:title" content="ResuBot - ResuMaster" />
          <body className="resubot-body" />
        </Helmet>

        {isAuthenticated ? (
          <Navigation_loggedin className="navigation-2202" />
        ) : (
          <Navigation className="navigation-2202" />
        )}

        <div className="resubot-block-container">
          <div className="resubot-block-title" onClick={handleReturn}>
            <img className="resubot-back-button-img" src='/img/chevron-left.png' alt="Back Button" />
            Experience {exp_index + 1}
          </div>

          <div className="resubot-new-chat-title">
            New Chat
          </div>

          <div className="resubot-bottom-left-text">
            <div className="resubot-bottom-left-text-title">
              {data.Experience.Title} at {data.Experience.Company}
            </div>
            <div className="resubot-bottom-left-text-duration-location">
              {data.Experience.Duration}
            </div>
            <div className="resubot-bottom-left-text-title">
              {data.Experience.Location}
            </div>
            {currentExperience.Responsibilities.map((summary, idx) => (
              <li
                className={`experience-description-list-item ${revisedDescriptionIndex.includes(idx) ? 'resubot-bold-text' : ''}`}
                key={idx}
              >
                {summary}
              </li>
            ))}
            <button className="resubot-save-button" onClick={handleSave}>Save to result</button>
          </div>

          <div className="resubot-bottom-right-chat-container">
            <div className="resubot-chat-history" ref={chatHistoryRef}>
              <div className="resubot-chat-message">
                <img className="resubot-chatbot-icon" src='/img/resubot_icon.png' alt="Chatbot Icon" />
                <div className="resubot-chat-response">
                  👋 Hello, I'm ResuBot! I'll help fine-tune your resume and assist with analysis and brainstorming. Here are some sample tasks:
                  <ul className="resubot-response-list">
                    <li>Shorten this experience to 3 bullet points</li>
                    <li>Make all the bullet points concise</li>
                    <li>Rewrite the 1st and 3rd bullet point to showcase my leadership</li>
                    <li>Add some relevant skills related to python in this experience</li>
                    <li>What keywords can I add to become a stronger candidate for a full-stack developer position?</li>
                  </ul>
                </div>
              </div>

              {chatHistory.map((entry, idx) => (
                <div key={idx} className={entry.type === 'user' ? 'resubot-user-prompt-message' : 'resubot-chat-message'}>
                  {entry.type === 'bot' ? (
                    <>
                      <img className="resubot-chatbot-icon" src='/img/resubot_icon.png' alt="Chatbot Icon" />
                      <div className='resubot-chatbot-reply-section'>
                      <div className="resubot-chat-response">
                          {entry.withUndo ? entry.message.Explanation : entry.message}
                      </div>
                        {entry.withUndo && idx === latestUndoIndex && (
                          <button className="resubot-undo-button" onClick={handleUndo}>
                            <img src='/img/file-text.png' alt="Undo Icon" />
                            {undoText}
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="resubot-user-prompt">{entry.message}</div>
                  )}
                </div>
              ))}

              {loading && (
                <div className="resubot-chat-message">
                  <img className="resubot-chatbot-icon" src='/img/resubot_icon.png' alt="Chatbot Icon" />
                  <div className="resubot-chat-response">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                </div>
              )}
            </div>

            <div className="resubot-chat-input-container">
                <textarea
                    className="resubot-chat-input"
                    placeholder="Message ResuBot"
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    maxLength={300} // Set character limit here
                />
                <button className="resubot-chat-submit" onClick={sendToServer}>
                    <img className="resubot-submit-icon" src='/img/stop-circle.png' alt="Submit" />
                </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>Please log in first</div>
    )
  );
};
