import React, { useState, useRef, useEffect } from 'react';

const CustomSort = ({sortInfo, handleSortInfo, searchInput, setSubmittedSearch}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sortedOption, setSortedOption] = useState('last-updated');
    const dropdownRef = useRef(null);

    const options = [
        { value: 'last-updated', label: 'Last Updated' },
        { value: 'last-created', label: 'Last Created' }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSortChange = (newOption) => {
        if (newOption !== sortedOption) {
            setSortedOption(newOption);
            if (newOption === 'last-updated') {
                handleSortInfo('last_updated');
                setSubmittedSearch(searchInput.trim());
                // alert(`Sorted by: Last Updated`);
            } else {
                handleSortInfo('last_created');
                setSubmittedSearch(searchInput.trim());
                // alert(`Sorted by: Last Created`);
            }
        }
        setIsOpen(false);
    };

    return (
        <div ref={dropdownRef} className="myapplication-custom-sort" style={{
            position: 'relative', display: 'inline-block', fontFamily: 'Arial, sans-serif', fontSize: '14px'}}>
            <div className="myapplication-sort-container" onClick={() => setIsOpen(!isOpen)}>
                <img src="/img/sort.png" alt="filter icon" width="16" height="16" style={{marginLeft: '5px'}}/>
                <span style={{fontFamily: 'Open Sans', fontWeight: '600', fontSize: '14px', marginLeft: '6px'}}>
                    <span style={{ color: "#AAAAAA" }}>Sorted by </span>
                    <span style={{ color: "#333333" }}>
                        {options.find(opt => opt.value === sortedOption)?.label}
                    </span>
                </span>
            </div>
            {isOpen && (
                <div style={{position: 'absolute', top: '100%', left: '40%', right: 0, marginTop: '4px', background: 'white',
                    border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', zIndex: 1000}}>
                    {options.map(option => (
                        <div key={option.value} 
                            onClick={() => handleSortChange(option.value)}
                            style={{padding: '8px 12px', cursor: 'pointer', display: 'flex', alignItems: 'center', 
                                justifyContent: 'space-between', color: '#333333', fontFamily: 'Open Sans', fontWeight: 600}}>
                            {option.label}
                            {sortedOption === option.value && (
                                <img src="/img/checkmark.png" alt="filter icon" width="16" height="16"/>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSort;