import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Button = ({ className, text = "Upload Your Resume", showArrow = false, ...props }) => {
  return (
    <button className={`button ${className}`} {...props}>
      <div className={`text ${className}-text`}>
        {text}
        {showArrow && <img src="/img/arrow-right.png" alt="white-arrow" className="white-arrow" />}
      </div>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showArrow: PropTypes.bool,
};
