import React, { useState, useEffect } from 'react';
import Applications from './Applications';
import { useNavigate } from 'react-router-dom';
import { Pagination, Box } from '@mui/material';

const ApplicationHistory = ({ userApplicationsInfos, onSubmit, onDelete, currentResume }) => {
    // const [applications] = useState(applicationData);
    const navigate = useNavigate();
    const [expandedId, setExpandedId] = useState(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const itemsPerPage = 5;

    const handleNewRevision = () => {
        if (currentResume && currentResume.Record_exist) {
            navigate('/DashboardRevision');
        } else {
            navigate('/uploadResume');
        }
        
    };
    // console.log(userApplicationsInfos)

    useEffect(() => {
        if (userApplicationsInfos) {
            setCount(Math.ceil(userApplicationsInfos.length / itemsPerPage));
        }
    }, [userApplicationsInfos]);

    if (!userApplicationsInfos || userApplicationsInfos.length === 0) {
        console.log('userapplication empty');
        return (
        <div className='blank_box'>
            <img src="/img/history_empty.png" alt="history empty icon" />
            <p>It looks like you haven't saved any applications yet. To get started, upload your resume and begin customizing it for your job applications.</p>
            <button onClick={handleNewRevision}>
                {currentResume && currentResume.Record_exist ? 'Start New Revision' : 'Upload Resume'}
            </button>
        </div>);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedApplications = userApplicationsInfos.slice(startIndex, endIndex);

    return (
        <div>
            {displayedApplications.map((userApplicationsInfo) => (
                <Applications 
                    key={userApplicationsInfo.id}
                    company={{
                        id: userApplicationsInfo.id,
                        company_name: userApplicationsInfo.company,
                        domain: userApplicationsInfo.domain,
                        position: userApplicationsInfo.job_position,
                        coverletter_json: userApplicationsInfo.coverletter_json,
                        job_description: userApplicationsInfo.job_description,
                        resume_json: userApplicationsInfo.resume_json,
                        status: userApplicationsInfo.application_status,
                        applied_date: userApplicationsInfo.applied_date,
                        interview_date: userApplicationsInfo.interview_date,
                        screen_date: userApplicationsInfo.screen_date,
                        offer_date: userApplicationsInfo.offer_date,
                        rejected_date: userApplicationsInfo.rejected_date
                    }}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                />
            ))}
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                <Pagination 
                    count={count} 
                    page={page}
                    onChange={handlePageChange}
                />
            </Box>
        </div>
    );
};

export default ApplicationHistory;