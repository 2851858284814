import React from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import "./style.css";

export const Contact = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="contact">
      <Helmet>
        <title>Contact - ResuMaster</title>
        <meta property="og:title" content="Contact - ResuMaster" />
        <script src="//embed.typeform.com/next/embed.js"></script>
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <Link to="javascript:history.back()" className="back-button back-button-white">
        <img className="back-button-img" src='/img/chevron-left-white.png' />
        <div className="back-button-text">Back</div>
      </Link>
      <div className="contact-content">
        <p className="contact-title">
          Welcome to RESUMaster.AI! <br />
          Have questions or need guidance?
        </p>
        <a className="contact-email" href="mailto:admin@resumaster.ai?subject=Customer%20Support" > Email: admin@resumaster.ai</a>
        <div className="contact-dc">
          <img className="contact-dc-img" alt="ic_baseline-discord" src="/img/reddit_icon.png" />
          <p className="contact-dc-text">
            Stay in the loop by joining us on
            &nbsp;
            <a href="https://reddit.com/r/ResuMaster" rel="noopener noreferrer" target="_blank">
              <span className="text-wrapper-dc">Reddit Community</span>
            </a>
            &nbsp;
            where we share valuable tips and insights. Feel free to leave any feedback there as well!
          </p>
        </div>
        <div className="contact-dc">
          <img className="contact-dc-img" alt="ic_baseline-discord" src="/img/ic_baseline-discord-white.png" />
          <p className="contact-dc-text">
            You can also join our
            &nbsp;
            <a href="https://discord.gg/YURMHY9JNt" rel="noopener noreferrer" target="_blank">
              <span className="text-wrapper-dc">Discord</span>
            </a>
            &nbsp;
            to contact us.
          </p>
        </div>
      </div>
      <div className="feedback-box">
        <div
          data-tf-widget="MUjQuSF6"
          data-tf-opacity="100"
          data-tf-iframe-props="title=Resumaster.AI feedbacks"
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          style={{ width: '100%', height: '700px', borderRadius: 10}}
        />
      </div>

    </div>

  );
};
