import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import './JobDescriptionModal.css';

const JobDescriptionModal = ({ isOpen, onClose, applicationID }) => {
    if (!isOpen) return null;

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [loading, setLoading] = useState(true); // Track loading state
	const dropdownRef = useRef(null);
    const [jobDescription, setJobDescription] = useState('');

	const handleClose = (e) => {
		e.stopPropagation();
		onClose();
	};

    const fetchJobDescription = async () => {
        setLoading(true); // Start loading
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            setLoading(false);
            return
        }
        const token = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append('application_id', applicationID);
        fetch('/resumasterapi/fetch_application_jd', { //api endpoint
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            if (response.status == 401) {
                throw new Error('Sorry, you must login first')
            }
            return response.json();
        }).then(data => {
            setJobDescription(data.jobDescription);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            alert(error.message);  
        });
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose(); // Close the modal if clicked outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component is unmounted
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        if (isOpen && applicationID) {
            fetchJobDescription(); // Fetch job description when the modal opens
        }
    }, [isOpen, applicationID]); // Re-fetch job description if applicationID changes

	return (
		<div className="add-application-modal-overlay">
			<div className="add-application-modal-content" ref={dropdownRef} onClick={e => e.stopPropagation()}>
				<div className="add-application-modal-scroll-content">
                    <div className="job-description-modal-header">
                        <h2 className="job-description-modal-title">Job Description Preview</h2>
                            <img 
                                src="/img/ic_round-close.png" 
                                alt="Close" 
                                className="close-icon" 
                                onClick={handleClose} // This function will close the modal
                            />
                    </div>
                    {loading ? (<p>Loading...</p>) :
                    (<div className="popup-text-holder" dangerouslySetInnerHTML={{ __html: jobDescription }} />)}
				</div>
			</div>
		</div>
	);
};

export default JobDescriptionModal;