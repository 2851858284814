import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook
//---------------------------------auhentication code----------------------------------------
import "./style.css";

export const PaymentSuccess = () => {

    const { isAuthenticated, loginWithRedirect, logout, user, isLoading, getAccessTokenSilently } = useAuth0();

    if (isLoading) {
        return <div>Loading ......</div>;
    }

    const [details, setDetails] = useState(null);
    const [error, setError] = useState('');
    const [transaction_type, setTransaction_type] = useState('');
    const location = useLocation();
   
    const plans = [
        { id: 1, name: 'subscription', description: 'Enjoy ulimitated access to our resume and cover letter revision services!' },
        { id: 2, name: 'plan-a', description: '3000 ResuCredits have been added to your account'},
        { id: 3, name: 'plan-b', description: '1000 ResuCredits have been added to your account' },
        { id: 4, name: 'plan-c', description: '500 ResuCredits have been added to your account'},
        { id: 5, name: 'plan-d', description: '200 ResuCredits have been added to your account'}
      ];

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        const transaction_type_pass_in = queryParams.get('type');
        setTransaction_type(queryParams.get('type'));
        if (sessionId) {
            verifyPaymentSession(sessionId, transaction_type_pass_in);
        } else {
            setError('No session ID provided.');
        }
    }, [location]);

    const verifyPaymentSession = async (sessionId, type) => {
        const url = `https://resumaster.ai/resumasterapi/verify-payment?session_id=${sessionId}&type=${type}`;
        const token = await getAccessTokenSilently();
        fetch(url, {
            method: 'POST',
            headers: {
                // Assuming 'getAccessTokenSilently' is available through your Auth0 React SDK context
                'Authorization': `Bearer ${token}`  // Make sure `yourAuthToken` is defined or retrieved from auth context
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to verify the payment session.');
            }
            return response.json();
        })
        .then(data => {
            setDetails(data);
        })
        .catch(error => {
            setError(error.message);
        });
    };

    if (!isAuthenticated) {
        return <div>Your are not logged in, please login first</div>;
    }
    
    const purchasedPlan = plans.find(plan => plan.name.toString() === transaction_type.toString());

    return (
        <div className="body-container">
            {details ? (
                <>
                <div className="successwindow-container">
                    <h1>Thank you for your order!</h1>
                    <p>Payment Success &#127881; <br /> {purchasedPlan.description}</p>
                    <a href="/" className="subscribe-button">
                        Continue to home
                    </a>
                </div>
                    
                </>
            ) : (
                <p>{error || "Loading..."}</p>
            )}
        </div>
    );
}